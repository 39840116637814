@keyframes placeHolderShimmer {
  0% {
    background-position: -1277px 0; }
  100% {
    background-position: 1277px 0; } }

.style__text___1nttx {
  box-sizing: border-box;
  display: inline-block;
  color: #f2f5f7;
  background-color: #f2f5f7;
  line-height: 0.5rem; }

.style__container-large___3XKWL .style__text___1nttx:first-child {
  margin-bottom: 1rem; }
