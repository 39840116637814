/*
  This file will be loaded asynchronously during page load. Non-critical
  styles (not needed for initial above-the-fold content) go here.
*/
/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.uni-btn.follow-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .uni-btn.follow-button svg {
    margin-right: 0.25rem;
    height: 16px;
    width: 16px;
    position: relative;
    top: -1px; }
  .uni-btn.follow-button.following {
    background: #78a100; }
    .uni-btn.follow-button.following path, .uni-btn.follow-button.following rect {
      color: #e6f2ff;
      fill: #e6f2ff; }
    .uni-btn.follow-button.following.allow-unfollow:hover {
      background: #8bbb00; }
  .uni-btn.follow-button.no-label {
    height: 40px;
    width: 40px;
    padding: 0; }
    .uni-btn.follow-button.no-label .label {
      display: none; }
  .uni-btn.follow-button.uni-btn--primary path, .uni-btn.follow-button.uni-btn--primary rect {
    color: #e6f2ff;
    fill: #e6f2ff; }

.fade-wait-leave {
  opacity: 1; }

.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity 0.4s ease-in; }

.fade-wait-enter {
  opacity: 0; }

.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity 0.4s ease-in 0.3s; }

.fade-wait-leave {
  opacity: 1; }

.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity 0.4s ease-in; }

.fade-wait-enter {
  opacity: 0; }

.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity 0.4s ease-in 0.3s; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -1277px 0; }
  100% {
    background-position: 1277px 0; } }

@font-face {
  font-family: trade;
  font-style: normal;
  font-weight: normal;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.svg#trade") format("svg"); }

@font-face {
  font-family: trade;
  font-style: normal;
  font-weight: bold;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.svg#trade") format("svg"); }

@font-face {
  font-family: trade-condensed-heavy;
  font-style: normal;
  font-weight: bold;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.svg#trade-condensed-heavy") format("svg"); }

#suggestions-container {
  min-height: 500px; }
  @media only screen and (min-width: 57.25em) {
    #suggestions-container {
      min-height: 500px; } }
  .desktop-only #suggestions-container {
    min-height: 500px; }

.non-recommendation-design .module {
  background: white;
  border: 1px solid #e4e7eb;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 20px 20px 0;
  width: 100%; }
  @media only screen and (min-width: 57.25em) {
    .non-recommendation-design .module {
      width: 50%; } }
  .desktop-only .non-recommendation-design .module {
    width: 50%; }
  @media only screen and (min-width: 66em) {
    .non-recommendation-design .module {
      width: 100%; } }
  .desktop-only .non-recommendation-design .module {
    width: 100%; }

.non-recommendation-design .suggested-head {
  color: rgba(19, 41, 63, 0.65);
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 8px;
  margin-bottom: 12px;
  margin-top: 0;
  border-bottom: 1px solid #e4e7eb; }

.non-recommendation-design .suggested-video-container {
  display: flex;
  transition: all, 0.25s;
  margin-bottom: 20px; }
  .non-recommendation-design .suggested-video-container:hover .svg-play {
    opacity: .8; }

.non-recommendation-design .thumb-container {
  padding-right: 10px;
  position: relative; }
  .non-recommendation-design .thumb-container .thumb-container-badge {
    background-position: center center;
    background-size: 2rem 2rem;
    border: 1px solid #232A31;
    border-radius: 50%;
    height: 2rem;
    left: -1rem;
    position: absolute;
    top: 0.25rem;
    width: 2rem; }
  .non-recommendation-design .thumb-container .suggested-video-thumb {
    border: none;
    height: 68px;
    width: 120px; }
  .non-recommendation-design .thumb-container .svg-play {
    height: 35px;
    width: 35px;
    padding: 7px 9px;
    position: absolute;
    left: 43px;
    top: 18px;
    opacity: 0;
    background: #232A31;
    border: 1px solid white;
    border-radius: 50%;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s; }
    .no-smil .non-recommendation-design .thumb-container .svg-play {
      display: none; }
    .non-recommendation-design .thumb-container .svg-play svg {
      height: 20px;
      width: 20px; }
      .non-recommendation-design .thumb-container .svg-play svg path {
        fill: #fff; }

.non-recommendation-design .suggested-video-info {
  width: auto;
  width: calc(100% - 130px); }
  .non-recommendation-design .suggested-video-info .suggested-video-title {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px;
    margin-top: -2px;
    letter-spacing: 0.01em;
    font-weight: 700;
    color: #555;
    line-height: 1;
    display: block;
    font-family: trade-condensed-heavy; }
  .non-recommendation-design .suggested-video-info .suggested-video-athlete {
    margin: 0;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 400;
    color: rgba(19, 41, 63, 0.65); }

.non-recommendation-design .suggested-athlete-container {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s; }
  .non-recommendation-design .suggested-athlete-container:hover .svg-arrow {
    opacity: .8; }

.non-recommendation-design .photo-holder {
  background-size: cover;
  width: 65px;
  position: relative;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  height: 65px;
  overflow: hidden;
  background-color: #232A31;
  border: 1px solid #232A31; }
  .non-recommendation-design .photo-holder.no-img {
    background-color: transparent;
    border: none; }

.non-recommendation-design .athlete-holder {
  width: auto;
  width: calc(100% - 75px);
  float: left;
  margin-top: 4px; }

.non-recommendation-design .svg-arrow {
  height: 25px;
  width: 25px;
  padding: 2.5px 4.5px;
  position: absolute;
  left: 20px;
  top: 20px;
  opacity: 0;
  background: #232A31;
  border: 1px solid white;
  fill: white;
  border-radius: 50%;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s; }
  .no-smil .non-recommendation-design .svg-arrow {
    display: none; }

.non-recommendation-design .suggested-athlete-name {
  margin: 0 0 5px;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #555;
  font-family: trade-condensed-heavy; }

.non-recommendation-design .suggested-athlete-info, .non-recommendation-design .suggested-athlete-school {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  color: rgba(19, 41, 63, 0.65); }

.non-recommendation-design .suggested-video-label {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 5px;
  margin-right: 5px;
  font-size: 12px;
  border-radius: 2px;
  color: rgba(19, 41, 63, 0.65);
  background: #edf0f2; }

.non-recommendation-design .suggested-video-footer {
  border-top: 1px solid #E8E8E8; }

.non-recommendation-design .suggested-video-footer a {
  display: block;
  padding: 15px 0;
  text-align: center; }

.non-recommendation-design .more-suggestions-icon {
  height: .25rem;
  width: 1rem; }

.recommendation-design .module {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 16px; }
  @media only screen and (min-width: 65em) {
    .recommendation-design .module {
      width: 50%;
      background: white;
      border: 1px solid #E8E8E8;
      border-radius: 3px;
      margin-bottom: 20px;
      padding: 20px 20px 0; } }
  .desktop-only .recommendation-design .module {
    width: 50%;
    background: white;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 20px 20px 0; }
  @media only screen and (min-width: 66em) {
    .recommendation-design .module {
      width: 100%; } }
  .desktop-only .recommendation-design .module {
    width: 100%; }

.recommendation-design .suggested-head {
  color: rgba(19, 41, 63, 0.65);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 0;
  font-family: helvetica, arial, sans-serif;
  line-height: 1.071428571; }
  @media only screen and (min-width: 65em) {
    .recommendation-design .suggested-head {
      color: rgba(19, 41, 63, 0.65);
      font-size: .875rem;
      font-weight: 700;
      padding-bottom: 8px;
      margin-bottom: 12px;
      margin-top: 0;
      border-bottom: 1px solid #e4e7eb; } }
  .desktop-only .recommendation-design .suggested-head {
    color: rgba(19, 41, 63, 0.65);
    font-size: .875rem;
    font-weight: 700;
    padding-bottom: 8px;
    margin-bottom: 12px;
    margin-top: 0;
    border-bottom: 1px solid #e4e7eb; }

.recommendation-design .suggested-video-container {
  display: flex;
  transition: all, 0.25s;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e7eb; }
  .recommendation-design .suggested-video-container--placeholder {
    margin-bottom: 5rem; }
  .recommendation-design .suggested-video-container:hover .svg-play {
    opacity: .8; }
  @media only screen and (min-width: 65em) {
    .recommendation-design .suggested-video-container {
      margin-bottom: 20px;
      border-bottom: none; } }
  .desktop-only .recommendation-design .suggested-video-container {
    margin-bottom: 20px;
    border-bottom: none; }

.recommendation-design .thumb-container {
  padding-right: 10px;
  position: relative; }
  .recommendation-design .thumb-container .thumb-container-badge {
    background-position: center center;
    background-size: 2rem 2rem;
    border: 1px solid #232A31;
    height: 2rem;
    border-radius: 50%;
    left: -1rem;
    position: absolute;
    top: 0.25rem;
    width: 2rem; }
  .recommendation-design .thumb-container .suggested-video-thumb {
    border: none;
    height: 68px;
    width: 120px; }
    .recommendation-design .thumb-container .suggested-video-thumb--placeholder {
      color: #f2f5f7;
      background-color: #f2f5f7; }
  .recommendation-design .thumb-container .svg-play {
    height: 35px;
    width: 35px;
    padding: 7px 9px;
    position: absolute;
    left: 43px;
    top: 18px;
    opacity: 0;
    background: #232A31;
    border: 1px solid white;
    border-radius: 50%;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s; }
    .no-smil .recommendation-design .thumb-container .svg-play {
      display: none; }
    .recommendation-design .thumb-container .svg-play svg {
      height: 20px;
      width: 20px; }
      .recommendation-design .thumb-container .svg-play svg path {
        fill: #fff; }

.recommendation-design .suggested-video-info {
  width: auto;
  width: calc(100% - 130px); }
  .recommendation-design .suggested-video-info .suggested-video-title {
    font-size: 14px;
    margin-top: -2px;
    color: #4e5d6c;
    line-height: 1.5;
    display: block;
    font-family: helvetica, arial, sans-serif; }
    .recommendation-design .suggested-video-info .suggested-video-title--placeholder {
      margin-bottom: 0.2rem; }
    @media only screen and (min-width: 65em) {
      .recommendation-design .suggested-video-info .suggested-video-title {
        font-weight: 700;
        display: block; } }
    .desktop-only .recommendation-design .suggested-video-info .suggested-video-title {
      font-weight: 700;
      display: block; }
  .recommendation-design .suggested-video-info .suggested-video-athlete {
    margin: 0;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 400;
    color: rgba(19, 41, 63, 0.65); }

.recommendation-design .suggested-athlete-container {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s; }
  .recommendation-design .suggested-athlete-container:hover .svg-arrow {
    opacity: .8; }

.recommendation-design .photo-holder {
  background-size: cover;
  width: 65px;
  position: relative;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  height: 65px;
  overflow: hidden;
  background-color: #232A31;
  border: 1px solid #232A31; }
  .recommendation-design .photo-holder--no-img {
    background-color: transparent;
    border: none; }
  .recommendation-design .photo-holder--placeholder {
    color: #f2f5f7;
    background-color: #f2f5f7;
    border: none; }

.recommendation-design .athlete-holder {
  width: auto;
  width: calc(100% - 75px);
  float: left;
  margin-top: 4px; }

.recommendation-design .svg-arrow {
  height: 25px;
  width: 25px;
  padding: 2.5px 4.5px;
  position: absolute;
  left: 20px;
  top: 20px;
  opacity: 0;
  background: #232A31;
  border: 1px solid white;
  fill: white;
  border-radius: 50%;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s; }
  .no-smil .recommendation-design .svg-arrow {
    display: none; }

.recommendation-design .suggested-athlete-name {
  margin: 0 0 5px;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #555;
  font-family: trade-condensed-heavy; }

.recommendation-design .suggested-athlete-info, .recommendation-design .suggested-athlete-school {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  color: rgba(19, 41, 63, 0.65); }

.recommendation-design .suggested-video-label {
  display: inline-block;
  margin-top: 5px;
  padding: 3px 5px;
  margin-right: 5px;
  font-size: 12px;
  border-radius: 2px;
  color: rgba(19, 41, 63, 0.65);
  background-color: white; }
  @media only screen and (min-width: 65em) {
    .recommendation-design .suggested-video-label {
      background: #edf0f2; } }
  .desktop-only .recommendation-design .suggested-video-label {
    background: #edf0f2; }

@media only screen and (min-width: 65em) {
  .recommendation-design .suggested-video-footer {
    border-top: 1px solid #e4e7eb; } }

.desktop-only .recommendation-design .suggested-video-footer {
  border-top: 1px solid #e4e7eb; }

.recommendation-design .suggested-video-footer a {
  display: block;
  padding: 15px 0;
  text-align: center; }

.recommendation-design .more-suggestions-icon {
  height: .25rem;
  width: 1rem; }

@media only screen and (min-width: 65em) {
  .recommendation-design .views-label {
    color: rgba(19, 41, 63, 0.4); } }

.desktop-only .recommendation-design .views-label {
  color: rgba(19, 41, 63, 0.4); }

@font-face {
  font-family: trade;
  font-style: normal;
  font-weight: normal;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.svg#trade") format("svg"); }

@font-face {
  font-family: trade;
  font-style: normal;
  font-weight: bold;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.svg#trade") format("svg"); }

@font-face {
  font-family: trade-condensed-heavy;
  font-style: normal;
  font-weight: bold;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.svg#trade-condensed-heavy") format("svg"); }

.footer-page {
  background: #191f24;
  width: 100%;
  padding: 20px; }
  .footer-page .band::after {
    clear: both;
    content: "";
    display: table; }
  .footer-page ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .footer-page .btn-default, .footer-page .ko-btn-default {
    width: 100%; }

.nav-footer li {
  margin-bottom: 8px; }

.nav-footer p {
  margin: 0; }

.nav-footer a {
  display: block;
  border: 0;
  line-height: 2;
  font-size: 14px;
  font-family: trade, sans-serif;
  color: white;
  text-decoration: none;
  transition: .25s; }
  .nav-footer a:active {
    color: #009ce3; }
  .nav-footer a:hover {
    color: #009ce3; }

.nav-footer .top-level-nav {
  display: inline-block;
  float: left;
  width: 20%;
  margin-right: 2%; }

.nav-footer .social-links {
  display: inline-block;
  float: left;
  width: 20%; }

.nav-footer form {
  display: inline-block;
  float: left;
  width: 20%; }

.nav-footer .top-level-nav {
  display: none; }
  @media only screen and (min-width: 57.25em) {
    .nav-footer .top-level-nav {
      display: inline-block;
      width: 30%;
      margin-right: 5%; } }
  .desktop-only .nav-footer .top-level-nav {
    display: inline-block;
    width: 30%;
    margin-right: 5%; }
  @media only screen and (min-width: 66em) {
    .nav-footer .top-level-nav {
      width: 20%;
      margin-right: 2%;
      float: left; } }
  .desktop-only .nav-footer .top-level-nav {
    width: 20%;
    margin-right: 2%;
    float: left; }

.nav-footer .social-links {
  display: none; }
  @media only screen and (min-width: 57.25em) {
    .nav-footer .social-links {
      display: inline-block;
      margin-right: 0;
      width: 30%; } }
  .desktop-only .nav-footer .social-links {
    display: inline-block;
    margin-right: 0;
    width: 30%; }
  @media only screen and (min-width: 66em) {
    .nav-footer .social-links {
      width: 20%;
      float: left; } }
  .desktop-only .nav-footer .social-links {
    width: 20%;
    float: left; }

.nav-footer form {
  display: none; }
  @media only screen and (min-width: 66em) {
    .nav-footer form {
      display: inline-block;
      width: 20%;
      float: left; } }
  .desktop-only .nav-footer form {
    display: inline-block;
    width: 20%;
    float: left; }

.copyright {
  color: #8A8A8A;
  text-align: center; }
  @media only screen and (min-width: 57.25em) {
    .copyright {
      text-align: left;
      margin-top: 20px; } }
  .desktop-only .copyright {
    text-align: left;
    margin-top: 20px; }

.band.chunky {
  max-width: 1300px;
  margin: 0 auto; }

input[type='submit'].btn {
  *padding-top: 3px;
  *padding-bottom: 3px; }

button.btn::-moz-focus-inner,
input[type='submit'].btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

button.btn.btn-large,
input[type='submit'].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px; }

button.btn.btn-small,
input[type='submit'].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px; }

button.btn.btn-mini,
input[type='submit'].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px; }

fieldset {
  background: none;
  border: none;
  padding: 0;
  margin: 0; }

input[type=text], input[type=email] {
  width: 100%;
  font-family: sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #232A31;
  padding: 10px;
  margin-bottom: 6px;
  border: 1px solid #dbdbdb;
  outline: none;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  border-radius: 2px; }

input[type=text]:hover, input[type=email]:hover {
  background: #f7fcfe; }

input[type=text]:focus, input[type=email]:focus {
  background: white;
  box-shadow: 0 0 3px rgba(13, 141, 207, 0.8);
  -webkit-box-shadow: 0 0 3px rgba(13, 141, 207, 0.8);
  -moz-box-shadow: 0 0 3px rgba(13, 141, 207, 0.8); }

.btn-default, .ko-btn-default {
  position: relative;
  display: inline-block;
  padding: 4px 20px;
  margin-bottom: 0;
  font: normal 14px trade;
  text-rendering: optimizeLegibility;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  *border: 0;
  box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.6);
  border-radius: 4px; }
  .btn-default:hover, .ko-btn-default:hover {
    text-decoration: none;
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    transition: background-color .2s linear; }
  .btn-default:active, .ko-btn-default:active {
    top: 2px;
    outline: 0; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/*
Some fixes and overrides until we can go 100% Uniform
*/
.uni-btn,
.uni-btn-icon-only,
.uni-btn--medium,
.uni-btn--small,
.uni-btn--primary,
.uni-btn--secondary,
.uni-btn--confirm,
.uni-btn--destroy,
.uni-btn--subtle {
  font-family: helvetica, arial, sans-serif; }
  .uni-btn svg,
  .uni-btn-icon-only svg,
  .uni-btn--medium svg,
  .uni-btn--small svg,
  .uni-btn--primary svg,
  .uni-btn--secondary svg,
  .uni-btn--confirm svg,
  .uni-btn--destroy svg,
  .uni-btn--subtle svg {
    vertical-align: middle; }

.uni-btn__group--combo > button {
  transition: none;
  min-width: 7.5rem; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*doc
---
title: Reset
name: 03-Reset
category: Elements
---

We use the Eric Meyer reset with the addition of setting the default font to **Trade**, removing text-decoration on links, giving everything a line-height of 1, using `-webkit-font-smoothing: antialiased`, and setting  `-webkit-tap-highlight-color: transparent`.

*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  line-height: 1;
  font-family: trade, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent; }

a {
  text-decoration: none; }

/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

.ko-nav-mobile-menu-button,
.ko-nav-mobile-menu-right-button {
  height: 3rem;
  width: 3rem;
  font-weight: bold;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer; }
  .ko-nav-mobile-menu-button:hover,
  .ko-nav-mobile-menu-right-button:hover {
    background-color: #181d22; }
  .ko-nav-mobile-menu-button svg, .ko-nav-mobile-menu-button path, .ko-nav-mobile-menu-button polygon,
  .ko-nav-mobile-menu-right-button svg,
  .ko-nav-mobile-menu-right-button path,
  .ko-nav-mobile-menu-right-button polygon {
    fill: #FF6600; }
  .no-flexbox .ko-nav-mobile-menu-button:after, .no-flexbox
  .ko-nav-mobile-menu-right-button:after {
    content: "";
    display: table;
    clear: both; }
  .no-flexbox .ko-nav-mobile-menu-button svg,
  .no-flexbox .ko-nav-mobile-menu-button span,
  .no-flexbox .ko-nav-mobile-menu-button img, .no-flexbox
  .ko-nav-mobile-menu-right-button svg,
  .no-flexbox
  .ko-nav-mobile-menu-right-button span,
  .no-flexbox
  .ko-nav-mobile-menu-right-button img {
    height: 3rem;
    line-height: 3rem;
    vertical-align: middle;
    float: left; }
  .no-flexbox .ko-nav-mobile-menu-button, .no-flexbox
  .ko-nav-mobile-menu-right-button {
    text-align: center; }
  .ko-nav-mobile-menu-button svg, .ko-nav-mobile-menu-button path, .ko-nav-mobile-menu-button polygon,
  .ko-nav-mobile-menu-right-button svg,
  .ko-nav-mobile-menu-right-button path,
  .ko-nav-mobile-menu-right-button polygon {
    width: 1.5rem;
    height: 1.5rem; }
    .no-flexbox .ko-nav-mobile-menu-button svg, .no-flexbox .ko-nav-mobile-menu-button path, .no-flexbox .ko-nav-mobile-menu-button polygon, .no-flexbox
    .ko-nav-mobile-menu-right-button svg, .no-flexbox
    .ko-nav-mobile-menu-right-button path, .no-flexbox
    .ko-nav-mobile-menu-right-button polygon {
      display: inline-block;
      float: none;
      height: 3rem; }

.ko-nav-mobile-menu-right-button {
  left: auto;
  right: 0; }

a.ko-nav-team {
  height: 3rem;
  width: 3rem;
  font-weight: bold;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  border-right: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: start; }
  a.ko-nav-team:hover {
    background-color: #181d22; }
  a.ko-nav-team svg, a.ko-nav-team path, a.ko-nav-team polygon {
    fill: #FF6600; }
  .no-flexbox a.ko-nav-team:after {
    content: "";
    display: table;
    clear: both; }
  .no-flexbox a.ko-nav-team svg,
  .no-flexbox a.ko-nav-team span,
  .no-flexbox a.ko-nav-team img {
    height: 3rem;
    line-height: 3rem;
    vertical-align: middle;
    float: left; }
  @media only screen and (min-width: 65em) {
    a.ko-nav-team {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: none; } }
  .desktop-only a.ko-nav-team {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: none; }
  .no-flexbox a.ko-nav-team {
    height: auto;
    padding-top: 0;
    padding-bottom: 0; }
  a.ko-nav-team .ko-nav-team-dudes {
    display: none;
    width: 1.5rem;
    height: 1.5rem; }
    @media only screen and (min-width: 65em) {
      a.ko-nav-team .ko-nav-team-dudes {
        display: block;
        margin-right: .5rem; } }
    .desktop-only a.ko-nav-team .ko-nav-team-dudes {
      display: block;
      margin-right: .5rem; }
  @media only screen and (min-width: 65em) {
    a.ko-nav-team .ko-nav-team-icon {
      display: none; } }
  .desktop-only a.ko-nav-team .ko-nav-team-icon {
    display: none; }
  a.ko-nav-team .ko-nav-team-icon svg {
    height: 1rem;
    width: 1rem; }
  a.ko-nav-team .ko-nav-team-arrow {
    display: none; }
    @media only screen and (min-width: 65em) {
      a.ko-nav-team .ko-nav-team-arrow {
        display: block; } }
    .desktop-only a.ko-nav-team .ko-nav-team-arrow {
      display: block; }
  a.ko-nav-team .ko-nav-team-name {
    display: block;
    margin: 0 0.375rem 0 0;
    color: #FF6600;
    max-width: 12rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.ko-nav-mobile-header, .ko-nav {
  z-index: 0; }
  @media only screen and (min-width: 65em) {
    .ko-nav-mobile-header, .ko-nav {
      z-index: 100; } }
  .desktop-only .ko-nav-mobile-header, .desktop-only .ko-nav {
    z-index: 100; }
  .ko-nav-mobile-header a, .ko-nav a {
    color: #FFFFFF;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }

.ko-nav-icon {
  width: 1rem;
  height: 1rem;
  fill: rgba(255, 255, 255, 0.5);
  margin-right: .5rem; }
  .ko-nav-icon svg {
    height: 100%;
    width: 100%; }
  .ko-nav-icon path, .ko-nav-icon polygon {
    width: 1rem;
    height: 1rem;
    fill: rgba(255, 255, 255, 0.5); }
  @media only screen and (min-width: 65em) {
    .ko-nav-main-item .ko-nav-icon {
      display: none; } }
  .desktop-only .ko-nav-main-item .ko-nav-icon {
    display: none; }
  @media only screen and (min-width: 65em) {
    .ko-nav-help .ko-nav-icon, .ko-nav-sub .ko-nav-icon, .ko-nav-sub-double .ko-nav-icon {
      display: block; } }
  .desktop-only .ko-nav-help .ko-nav-icon, .desktop-only .ko-nav-sub .ko-nav-icon, .desktop-only .ko-nav-sub-double .ko-nav-icon {
    display: block; }
  .no-flexbox .ko-nav-icon {
    position: relative;
    top: 0.1875rem; }

.ko-nav-mobile-header {
  background-color: #232A31;
  height: 3rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  @media only screen and (min-width: 65em) {
    .ko-nav-mobile-header {
      display: none; } }
  html.no-rgba .ko-nav-mobile-header {
    display: none; }
  .desktop-only .ko-nav-mobile-header {
    display: none; }

.ko-mobile-title {
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  font-family: trade;
  font-weight: bold;
  text-transform: uppercase; }
  .no-flexbox .ko-mobile-title {
    line-height: 3rem; }

.ko-nav {
  position: absolute;
  top: 0;
  height: 100%;
  width: 16rem;
  background-color: #38434F;
  box-shadow: inset 0 0 2rem rgba(35, 42, 49, 0.75);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s; }
  @media only screen and (min-width: 65em) {
    .ko-nav {
      background: none; } }
  .desktop-only .ko-nav {
    background: none; }
  .ko-nav-sub-is-active .ko-nav {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%); }
    @media only screen and (min-width: 65em) {
      .ko-nav-sub-is-active .ko-nav {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); } }
    .desktop-only .ko-nav-sub-is-active .ko-nav {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
  .ko-nav-teamswitcher-is-active .ko-nav {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%); }
    @media only screen and (min-width: 65em) {
      .ko-nav-teamswitcher-is-active .ko-nav {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); } }
    .desktop-only .ko-nav-teamswitcher-is-active .ko-nav {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
  @media only screen and (min-width: 65em) {
    .ko-nav {
      position: relative;
      width: 100%;
      height: 3rem;
      background-color: #232A31;
      overflow: visible;
      display: -webkit-box;
      display: -moz-box;
      display: box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      box-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      -o-align-items: center;
      align-items: center;
      -ms-flex-align: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      -o-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: justify; } }
  html.no-rgba .ko-nav {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: #232A31;
    overflow: visible;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
  .desktop-only .ko-nav {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: #232A31;
    overflow: visible;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
  .no-flexbox .ko-nav:after {
    content: "";
    display: table;
    clear: both; }

.no-flexbox .ko-nav {
  display: block !important; }

@media only screen and (min-width: 65em) {
  .ko-nav-right,
  .ko-nav-left {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    .no-flexbox .ko-nav-right:after, .no-flexbox
    .ko-nav-left:after {
      content: "";
      display: table;
      clear: both; } }

.desktop-only .ko-nav-right, .desktop-only
.ko-nav-left {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }
  .no-flexbox .desktop-only .ko-nav-right:after, .no-flexbox .desktop-only
  .ko-nav-left:after {
    content: "";
    display: table;
    clear: both; }

@media only screen and (min-width: 65em) {
  .ko-nav-right > li,
  .ko-nav-left > li {
    position: relative; }
    .no-flexbox .ko-nav-right > li, .no-flexbox
    .ko-nav-left > li {
      float: left; } }

.desktop-only .ko-nav-right > li, .desktop-only
.ko-nav-left > li {
  position: relative; }
  .no-flexbox .desktop-only .ko-nav-right > li, .no-flexbox .desktop-only
  .ko-nav-left > li {
    float: left; }

.ko-nav-right > li:hover > a,
.ko-nav-left > li:hover > a {
  background-color: rgba(255, 255, 255, 0.1); }
  @media only screen and (min-width: 65em) {
    .ko-nav-right > li:hover > a,
    .ko-nav-left > li:hover > a {
      background-color: #181d22; } }
  .desktop-only .ko-nav-right > li:hover > a, .desktop-only
  .ko-nav-left > li:hover > a {
    background-color: #181d22; }

@media only screen and (min-width: 65em) {
  .ko-nav-right > li:hover ul,
  .ko-nav-left > li:hover ul {
    top: 100%;
    height: auto;
    opacity: .99;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s; } }

.desktop-only .ko-nav-right > li:hover ul, .desktop-only
.ko-nav-left > li:hover ul {
  top: 100%;
  height: auto;
  opacity: .99;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.ko-nav-right > li > a,
.ko-nav-left > li > a {
  padding: 0.75rem;
  width: 16rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s; }
  .no-flexbox .ko-nav-right > li > a, .no-flexbox
  .ko-nav-left > li > a {
    display: block; }
  @media only screen and (min-width: 65em) {
    .ko-nav-right > li > a,
    .ko-nav-left > li > a {
      padding: 0 0.75rem;
      color: #FFFFFF;
      height: 3rem;
      width: auto; }
      .no-flexbox .ko-nav-right > li > a, .no-flexbox
      .ko-nav-left > li > a {
        line-height: 3rem; } }
  .desktop-only .ko-nav-right > li > a, .desktop-only
  .ko-nav-left > li > a {
    padding: 0 0.75rem;
    color: #FFFFFF;
    height: 3rem;
    width: auto; }
    .no-flexbox .desktop-only .ko-nav-right > li > a, .no-flexbox .desktop-only
    .ko-nav-left > li > a {
      line-height: 3rem; }

@media only screen and (min-width: 65em) {
  .ko-nav-right > li > ul,
  .ko-nav-left > li > ul {
    height: 0; } }

.desktop-only .ko-nav-right > li > ul, .desktop-only
.ko-nav-left > li > ul {
  height: 0; }

.no-flexbox .ko-nav-left {
  float: left; }

.no-flexbox .ko-nav-right {
  float: right; }

#message-link {
  display: none; }
  @media only screen and (min-width: 65em) {
    #message-link {
      display: -webkit-box;
      display: -moz-box;
      display: box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex; } }
  .desktop-only #message-link {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }

.ko-nav-sub, .ko-nav-sub-double {
  position: absolute;
  top: -2000%;
  left: 100%;
  background-color: #38434F;
  -webkit-transition: top 0 ease 0.2s;
  -moz-transition: top 0 ease 0.2s;
  transition: top 0 ease 0.2s; }
  @media only screen and (min-width: 65em) {
    .ko-nav-sub, .ko-nav-sub-double {
      background: none; } }
  .desktop-only .ko-nav-sub, .desktop-only .ko-nav-sub-double {
    background: none; }
  .ko-nav-sub.ko-nav-sub-is-active, .ko-nav-sub-is-active.ko-nav-sub-double, .ko-nav-sub.ko-nav-teamswitcher-is-active, .ko-nav-teamswitcher-is-active.ko-nav-sub-double {
    top: 0;
    height: 100%;
    -webkit-transition: top 0 ease 0;
    -moz-transition: top 0 ease 0;
    transition: top 0 ease 0; }
    @media only screen and (min-width: 65em) {
      .ko-nav-sub.ko-nav-sub-is-active, .ko-nav-sub-is-active.ko-nav-sub-double, .ko-nav-sub.ko-nav-teamswitcher-is-active, .ko-nav-teamswitcher-is-active.ko-nav-sub-double {
        top: -2000%;
        height: auto; } }
    .desktop-only .ko-nav-sub.ko-nav-sub-is-active, .desktop-only .ko-nav-sub-is-active.ko-nav-sub-double, .desktop-only .ko-nav-sub.ko-nav-teamswitcher-is-active, .desktop-only .ko-nav-teamswitcher-is-active.ko-nav-sub-double {
      top: -2000%;
      height: auto; }
  @media only screen and (min-width: 65em) {
    .ko-nav-sub, .ko-nav-sub-double {
      left: 0;
      right: auto;
      opacity: 0;
      height: auto;
      background-color: #181d22;
      z-index: 100;
      padding: 0.5rem 0;
      display: block;
      overflow: hidden; } }
  .desktop-only .ko-nav-sub, .desktop-only .ko-nav-sub-double {
    left: 0;
    right: auto;
    opacity: 0;
    height: auto;
    background-color: #181d22;
    z-index: 100;
    padding: 0.5rem 0;
    display: block;
    overflow: hidden; }
  .ko-nav-sub li > a, .ko-nav-sub-double li > a {
    width: 16rem;
    padding: 0.75rem;
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center; }
    @media only screen and (min-width: 65em) {
      .ko-nav-sub li > a, .ko-nav-sub-double li > a {
        width: 10rem;
        height: auto;
        padding: 0.5rem 0.75rem;
        float: left; } }
    .desktop-only .ko-nav-sub li > a, .desktop-only .ko-nav-sub-double li > a {
      width: 10rem;
      height: auto;
      padding: 0.5rem 0.75rem;
      float: left; }
    .ko-nav-sub li > a:hover, .ko-nav-sub-double li > a:hover {
      background-color: rgba(255, 255, 255, 0.1); }
      @media only screen and (min-width: 65em) {
        .ko-nav-sub li > a:hover, .ko-nav-sub-double li > a:hover {
          color: #FF6600;
          background-color: transparent; }
          .ko-nav-sub li > a:hover svg, .ko-nav-sub-double li > a:hover svg, .ko-nav-sub li > a:hover path, .ko-nav-sub-double li > a:hover path, .ko-nav-sub li > a:hover polygon, .ko-nav-sub-double li > a:hover polygon {
            fill: #FF6600; } }
      .desktop-only .ko-nav-sub li > a:hover, .desktop-only .ko-nav-sub-double li > a:hover {
        color: #FF6600;
        background-color: transparent; }
        .desktop-only .ko-nav-sub li > a:hover svg, .desktop-only .ko-nav-sub-double li > a:hover svg, .desktop-only .ko-nav-sub li > a:hover path, .desktop-only .ko-nav-sub-double li > a:hover path, .desktop-only .ko-nav-sub li > a:hover polygon, .desktop-only .ko-nav-sub-double li > a:hover polygon {
          fill: #FF6600; }
  .ko-nav-sub.ko-nav-teamswitcher, .ko-nav-teamswitcher.ko-nav-sub-double {
    right: 100%;
    left: auto;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 2rem rgba(35, 42, 49, 0.75); }
    @media only screen and (min-width: 65em) {
      .ko-nav-sub.ko-nav-teamswitcher, .ko-nav-teamswitcher.ko-nav-sub-double {
        right: auto;
        left: 0;
        width: auto; } }
    .desktop-only .ko-nav-sub.ko-nav-teamswitcher, .desktop-only .ko-nav-teamswitcher.ko-nav-sub-double {
      right: auto;
      left: 0;
      width: auto; }
    .ko-nav-sub.ko-nav-teamswitcher > li > a, .ko-nav-teamswitcher.ko-nav-sub-double > li > a {
      width: calc(100% - 1px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .ko-nav-sub.ko-nav-teamswitcher > .ko-nav-teamswitcher-school, .ko-nav-teamswitcher.ko-nav-sub-double > .ko-nav-teamswitcher-school {
      color: #8A8A8A;
      margin: .5rem .8rem 0rem .8rem;
      font-size: 0.8rem;
      font-weight: bold; }
  @media only screen and (min-width: 65em) {
    .ko-nav-sub.ko-nav-sub-profile li > a, .ko-nav-sub-profile.ko-nav-sub-double li > a {
      width: 12.5rem; } }
  .desktop-only .ko-nav-sub.ko-nav-sub-profile li > a, .desktop-only .ko-nav-sub-profile.ko-nav-sub-double li > a {
    width: 12.5rem; }

.ko-nav-sub-double {
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 2rem rgba(35, 42, 49, 0.75); }
  @media only screen and (min-width: 65em) {
    .ko-nav-sub-double {
      width: 25rem;
      height: auto; } }
  .desktop-only .ko-nav-sub-double {
    width: 25rem;
    height: auto; }

@media only screen and (min-width: 65em) {
  .ko-nav-anchor-right {
    left: auto;
    right: 0; } }

.desktop-only .ko-nav-anchor-right {
  left: auto;
  right: 0; }

@media only screen and (min-width: 65em) {
  .ko-nav-account .ko-nav-icon {
    display: none; } }

.desktop-only .ko-nav-account .ko-nav-icon {
  display: none; }

.ko-nav-account .ko-nav-account-arrow {
  display: none;
  margin: 1rem 0 1rem 1rem; }
  @media only screen and (min-width: 65em) {
    .ko-nav-account .ko-nav-account-arrow {
      display: block; }
      .no-flexbox .ko-nav-account .ko-nav-account-arrow {
        float: left; } }
  .desktop-only .ko-nav-account .ko-nav-account-arrow {
    display: block; }
    .no-flexbox .desktop-only .ko-nav-account .ko-nav-account-arrow {
      float: left; }

@media only screen and (min-width: 65em) {
  .no-flexbox .ko-nav-username {
    float: left; } }

.desktop-only .no-flexbox .ko-nav-username {
  float: left; }

.ko-nav-avatar {
  display: none;
  width: 2rem;
  height: 2rem;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  margin: 0 0.75rem; }
  .ko-nav-avatar svg {
    fill: #FFFFFF; }
  @media only screen and (min-width: 65em) {
    .ko-nav-avatar {
      display: block; }
      .no-flexbox .ko-nav-avatar {
        float: left;
        margin-top: .5rem; } }
  .desktop-only .ko-nav-avatar {
    display: block; }
    .no-flexbox .desktop-only .ko-nav-avatar {
      float: left;
      margin-top: .5rem; }

@media only screen and (min-width: 65em) {
  .no-flexbox .ko-nav-account-arrow {
    float: left;
    height: 3rem; } }

.no-flexbox .desktop-only .ko-nav-account-arrow {
  float: left;
  height: 3rem; }

@media only screen and (min-width: 65em) {
  li a.ko-nav-help {
    min-width: 3rem;
    height: 3rem;
    width: 3rem;
    font-weight: bold;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    li a.ko-nav-help:hover {
      background-color: #181d22; }
    li a.ko-nav-help svg, li a.ko-nav-help path, li a.ko-nav-help polygon {
      fill: #FF6600; }
    .no-flexbox li a.ko-nav-help:after {
      content: "";
      display: table;
      clear: both; }
    .no-flexbox li a.ko-nav-help svg,
    .no-flexbox li a.ko-nav-help span,
    .no-flexbox li a.ko-nav-help img {
      height: 3rem;
      line-height: 3rem;
      vertical-align: middle;
      float: left; }
    li a.ko-nav-help .ko-nav-icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0; } }

.desktop-only li a.ko-nav-help {
  min-width: 3rem;
  height: 3rem;
  width: 3rem;
  font-weight: bold;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s; }
  .desktop-only li a.ko-nav-help:hover {
    background-color: #181d22; }
  .desktop-only li a.ko-nav-help svg, .desktop-only li a.ko-nav-help path, .desktop-only li a.ko-nav-help polygon {
    fill: #FF6600; }
  .no-flexbox .desktop-only li a.ko-nav-help:after {
    content: "";
    display: table;
    clear: both; }
  .no-flexbox .desktop-only li a.ko-nav-help svg,
  .no-flexbox .desktop-only li a.ko-nav-help span,
  .no-flexbox .desktop-only li a.ko-nav-help img {
    height: 3rem;
    line-height: 3rem;
    vertical-align: middle;
    float: left; }
  .desktop-only li a.ko-nav-help .ko-nav-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0; }

@media only screen and (min-width: 65em) {
  .ko-nav-mobile-label {
    display: none; } }

.desktop-only .ko-nav-mobile-label {
  display: none; }

.ko-sub-nav-heading, .ko-sub-nav-heading-current-team {
  width: 16rem;
  padding: 0.75rem;
  height: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  font-family: trade;
  font-weight: bold;
  text-transform: uppercase; }
  .no-flexbox .ko-sub-nav-heading, .no-flexbox .ko-sub-nav-heading-current-team {
    display: block; }
    .no-flexbox .ko-sub-nav-heading svg, .no-flexbox .ko-sub-nav-heading-current-team svg {
      position: relative;
      top: 0.1875rem; }
  .ko-sub-nav-heading svg, .ko-sub-nav-heading-current-team svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem; }
    .ko-sub-nav-heading svg path, .ko-sub-nav-heading-current-team svg path {
      fill: #FF6600; }
  @media only screen and (min-width: 65em) {
    .ko-sub-nav-heading, .ko-sub-nav-heading-current-team {
      display: none; } }
  .desktop-only .ko-sub-nav-heading, .desktop-only .ko-sub-nav-heading-current-team {
    display: none; }

.ko-sub-nav-heading-current-team {
  color: #FF6600 !important;
  cursor: pointer;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .ko-sub-nav-heading-current-team .ko-nav-icon path {
    fill: #FF6600; }
  .no-flexbox .ko-sub-nav-heading-current-team .ko-nav-icon {
    margin-left: .5rem;
    position: relative;
    top: 0.1875rem; }

html.ko-sidemenu-left-is-open {
  overflow: hidden; }
  @media only screen and (min-width: 65em) {
    html.ko-sidemenu-left-is-open {
      overflow: auto; } }
  .desktop-only html.ko-sidemenu-left-is-open {
    overflow: auto; }

.ko-wrap {
  background-color: #FFFFFF;
  z-index: 1;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }
  .ko-sidemenu-left-is-open .ko-wrap {
    -webkit-transform: translateX(16rem);
    -moz-transform: translateX(16rem);
    -ms-transform: translateX(16rem);
    -o-transform: translateX(16rem);
    transform: translateX(16rem);
    overflow: hidden; }
    @media only screen and (min-width: 65em) {
      .ko-sidemenu-left-is-open .ko-wrap {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        overflow: auto; } }
    .desktop-only .ko-sidemenu-left-is-open .ko-wrap {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      overflow: auto; }

/*
    1. Fix for Chrome 43's "ignoring the height rule bug" that appears
       to be fixed in Chrome 44.
       (https://code.google.com/p/chromium/issues/detail?id=484575)
*/
.ko-subheader {
  background-color: #38434F;
  color: #FFFFFF;
  padding: 0 1rem;
  height: 3rem;
  min-height: 3rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  @media only screen and (min-width: 65em) {
    .ko-subheader {
      height: 4rem;
      min-height: 4rem;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      -o-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: justify; }
      .no-flexbox .ko-subheader:after {
        content: "";
        display: table;
        clear: both; } }
  html.no-rgba .ko-subheader {
    height: 4rem;
    min-height: 4rem;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .no-flexbox html.no-rgba .ko-subheader:after {
      content: "";
      display: table;
      clear: both; }
  .desktop-only .ko-subheader {
    height: 4rem;
    min-height: 4rem;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .no-flexbox .desktop-only .ko-subheader:after {
      content: "";
      display: table;
      clear: both; }
  .ko-subheader h1 {
    display: none;
    margin-right: 2rem; }
    @media only screen and (min-width: 65em) {
      .ko-subheader h1 {
        display: block;
        color: #FFFFFF;
        font-family: trade;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2rem; }
        .no-flexbox .ko-subheader h1 {
          float: left; } }
    html.no-rgba .ko-subheader h1 {
      display: block;
      color: #FFFFFF;
      font-family: trade;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2rem; }
      .no-flexbox html.no-rgba .ko-subheader h1 {
        float: left; }
    .desktop-only .ko-subheader h1 {
      display: block;
      color: #FFFFFF;
      font-family: trade;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2rem; }
      .no-flexbox .desktop-only .ko-subheader h1 {
        float: left; }
  .ko-subheader > div {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .ko-subheader > div:after {
      content: "";
      display: table;
      clear: both; }
    .ko-subheader > div > a {
      color: #FFFFFF;
      padding: 1rem;
      cursor: pointer; }
      @media only screen and (min-width: 65em) {
        .ko-subheader > div > a {
          padding: 1.5rem; } }
      html.no-rgba .ko-subheader > div > a {
        padding: 1.5rem; }
      .desktop-only .ko-subheader > div > a {
        padding: 1.5rem; }
      .ko-subheader > div > a:first-child {
        margin-left: 0; }
      .ko-subheader > div > a:hover {
        background-color: #2d3640;
        color: #FFFFFF; }
      .ko-subheader > div > a.selected {
        background-color: #2d3640;
        font-weight: bold;
        text-transform: uppercase; }

.season-switcher {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: center; }
  .no-flexbox .season-switcher {
    text-align: center; }
    .no-flexbox .season-switcher:after {
      content: "";
      display: table;
      clear: both; }
    @media only screen and (min-width: 65em) {
      .no-flexbox .season-switcher {
        float: right;
        position: relative;
        top: .25rem; } }
    html.no-rgba .no-flexbox .season-switcher {
      float: right;
      position: relative;
      top: .25rem; }
    .desktop-only .no-flexbox .season-switcher {
      float: right;
      position: relative;
      top: .25rem; }
  .season-switcher .selected-season {
    margin: 0 1rem; }
    .no-flexbox .season-switcher .selected-season {
      display: inline-block; }
  .season-switcher .ko-switch-season {
    cursor: pointer;
    display: inline-block;
    height: 4rem;
    padding: 1.5rem 0.5rem; }
    .season-switcher .ko-switch-season:hover svg, .season-switcher .ko-switch-season:hover path {
      fill: #FF6600; }
    .season-switcher .ko-switch-season a {
      display: inline-block;
      height: 4rem; }
    .season-switcher .ko-switch-season svg, .season-switcher .ko-switch-season path {
      fill: #FFFFFF;
      width: 1rem;
      height: 1rem;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
      .no-flexbox .season-switcher .ko-switch-season svg, .no-flexbox .season-switcher .ko-switch-season path {
        display: inline-block;
        position: relative;
        top: .125rem; }
    .season-switcher .ko-switch-season.ko-season-disabled svg, .season-switcher .ko-switch-season.ko-season-disabled path {
      fill: rgba(255, 255, 255, 0.25); }
    .season-switcher .ko-switch-season.ko-season-disabled:hover {
      fill: rgba(255, 255, 255, 0.25);
      cursor: default; }

.ko-col-layout {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1; }
  .ko-col-layout .ko-two-col-left {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    @media only screen and (min-width: 37.5em) {
      .ko-col-layout .ko-two-col-left {
        -webkit-box-flex: 2;
        -moz-box-flex: 2;
        box-flex: 2;
        -webkit-flex: 2;
        -moz-flex: 2;
        -ms-flex: 2;
        flex: 2; } }
    html.no-rgba .ko-col-layout .ko-two-col-left {
      -webkit-box-flex: 2;
      -moz-box-flex: 2;
      box-flex: 2;
      -webkit-flex: 2;
      -moz-flex: 2;
      -ms-flex: 2;
      flex: 2; }
    .desktop-only .ko-col-layout .ko-two-col-left {
      -webkit-box-flex: 2;
      -moz-box-flex: 2;
      box-flex: 2;
      -webkit-flex: 2;
      -moz-flex: 2;
      -ms-flex: 2;
      flex: 2; }
    @media only screen and (min-width: 65em) {
      .ko-col-layout .ko-two-col-left {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        box-flex: 1;
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1; } }
    html.no-rgba .ko-col-layout .ko-two-col-left {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    .desktop-only .ko-col-layout .ko-two-col-left {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
  .ko-col-layout .ko-two-col-right {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: none;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    @media only screen and (min-width: 37.5em) {
      .ko-col-layout .ko-two-col-right {
        display: block;
        border-left: 1px solid #E8E8E8;
        box-shadow: inset 0 0 10px rgba(35, 42, 49, 0.1);
        -webkit-box-flex: 3;
        -moz-box-flex: 3;
        box-flex: 3;
        -webkit-flex: 3;
        -moz-flex: 3;
        -ms-flex: 3;
        flex: 3; } }
    html.no-rgba .ko-col-layout .ko-two-col-right {
      display: block;
      border-left: 1px solid #E8E8E8;
      box-shadow: inset 0 0 10px rgba(35, 42, 49, 0.1);
      -webkit-box-flex: 3;
      -moz-box-flex: 3;
      box-flex: 3;
      -webkit-flex: 3;
      -moz-flex: 3;
      -ms-flex: 3;
      flex: 3; }
    .desktop-only .ko-col-layout .ko-two-col-right {
      display: block;
      border-left: 1px solid #E8E8E8;
      box-shadow: inset 0 0 10px rgba(35, 42, 49, 0.1);
      -webkit-box-flex: 3;
      -moz-box-flex: 3;
      box-flex: 3;
      -webkit-flex: 3;
      -moz-flex: 3;
      -ms-flex: 3;
      flex: 3; }
    @media only screen and (min-width: 65em) {
      .ko-col-layout .ko-two-col-right {
        -webkit-box-flex: 2;
        -moz-box-flex: 2;
        box-flex: 2;
        -webkit-flex: 2;
        -moz-flex: 2;
        -ms-flex: 2;
        flex: 2; } }
    html.no-rgba .ko-col-layout .ko-two-col-right {
      -webkit-box-flex: 2;
      -moz-box-flex: 2;
      box-flex: 2;
      -webkit-flex: 2;
      -moz-flex: 2;
      -ms-flex: 2;
      flex: 2; }
    .desktop-only .ko-col-layout .ko-two-col-right {
      -webkit-box-flex: 2;
      -moz-box-flex: 2;
      box-flex: 2;
      -webkit-flex: 2;
      -moz-flex: 2;
      -ms-flex: 2;
      flex: 2; }
  .ko-col-layout .ko-three-col-left,
  .ko-col-layout .ko-three-col-middle,
  .ko-col-layout .ko-three-col-right {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .ko-col-layout .ko-three-col-middle,
  .ko-col-layout .ko-three-col-right {
    border-left: 1px solid #E8E8E8;
    box-shadow: inset 0 0 10px rgba(35, 42, 49, 0.1); }

@font-face {
  font-family: trade;
  font-weight: normal;
  font-style: normal;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular/regular.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular/regular.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular/regular.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular/regular.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular/regular.svg#trade") format("svg"); }

@font-face {
  font-family: trade;
  font-weight: normal;
  font-style: italic;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular-oblique/regular-oblique.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular-oblique/regular-oblique.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular-oblique/regular-oblique.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular-oblique/regular-oblique.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/regular-oblique/regular-oblique.svg#trade") format("svg"); }

@font-face {
  font-family: trade;
  font-weight: bold;
  font-style: normal;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-one/bold.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-one/bold.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-one/bold.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-one/bold.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-one/bold.svg#trade") format("svg"); }

@font-face {
  font-family: trade;
  font-weight: bold;
  font-style: italic;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-oblique/bold-oblique.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-oblique/bold-oblique.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-oblique/bold-oblique.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-oblique/bold-oblique.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/bold-oblique/bold-oblique.svg#trade") format("svg"); }

@font-face {
  font-family: trade-condensed;
  font-weight: normal;
  font-style: normal;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed/condensed.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed/condensed.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed/condensed.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed/condensed.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed/condensed.svg#trade-condensed") format("svg"); }

@font-face {
  font-family: trade-condensed;
  font-weight: normal;
  font-style: oblique;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-oblique/condensed-oblique.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-oblique/condensed-oblique.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-oblique/condensed-oblique.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-oblique/condensed-oblique.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-oblique/condensed-oblique.svg#trade-condensed") format("svg"); }

@font-face {
  font-family: trade-condensed;
  font-weight: bold;
  font-style: normal;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold/condensed-bold.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold/condensed-bold.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold/condensed-bold.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold/condensed-bold.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold/condensed-bold.svg#trade-condensed") format("svg"); }

@font-face {
  font-family: trade-condensed;
  font-weight: bold;
  font-style: oblique;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold-oblique/condensed-bold-oblique.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold-oblique/condensed-bold-oblique.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold-oblique/condensed-bold-oblique.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold-oblique/condensed-bold-oblique.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-bold-oblique/condensed-bold-oblique.svg#trade-condensed") format("svg"); }

@font-face {
  font-family: trade-heavy;
  font-weight: bold;
  font-style: normal;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/heavy/heavy.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/heavy/heavy.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/heavy/heavy.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/heavy/heavy.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/heavy/heavy.svg#trade-heavy") format("svg"); }

@font-face {
  font-family: trade-heavy-condensed;
  font-weight: bold;
  font-style: normal;
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-heavy/condensed-heavy.eot");
  src: url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-heavy/condensed-heavy.eot?#iefix") format("embedded-opentype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-heavy/condensed-heavy.woff") format("woff"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-heavy/condensed-heavy.ttf") format("truetype"), url("//d3hhcdv9i08ck8.cloudfront.net/trade-gothic/condensed-heavy/condensed-heavy.svg#trade-heavy-condensed") format("svg"); }

/*doc
---
title: Buttons
name: buttons
category: Elements
---

These are the default button styles that should be used throughout Hudl. A few notes:
  - We use **Title Case** on our buttons.
  - Feel free to use an icon with the button
  - Your **element** doesn't really matter (you can use an `<a>` or a `<button>` or a `<div>`)
  - You can disable a button by either adding the `disabled` attribute or by adding a class of `disabled`

### Inline Block Usage

Buttons are inline-block by default. If you need several inline-block buttons, wrap them in a container with a class `btn-inline-group` to give them a little margin.

```html_example
<div class="btn-inline-group">
    <button class="btn-primary">Primary</button>
    <a href="#" class="btn-default">Everyday Action</a>
    <div class="btn-default disabled">Can't Click</div>
    <button class="btn-primary" disabled>Won't Click</button>
    <span class="btn-cancel">Cancel</span> <!-- You can also use btn-text -->
</div>
```

### Block Usage

Add an additional class of `btn-block` for a button that fills its container

```html_example
<button class="btn-primary btn-block">Primary</button>
```

### Loading Buttons

Add an additional class of `btn-loading` to get a spinner on a button!

```html_example
<div class="btn-inline-group">
    <button class="btn-default btn-loading" disabled>It's Happening!</button>
    <button class="btn-primary btn-loading" disabled>It's Really Happening!</button>
</div>
```

### Icons in Buttons

Throw an `<img>` in a button, no problem.

```html_example
<div class="btn-inline-group">
    <button class="btn-default">
        <img src="icons/add.svg">Create an Event
    </button>
    <button class="btn-danger">
        <img src="icons/delete.svg">Delete an Event
    </button>
    <button class="btn-primary">
        <img src="icons/clip.svg">Watch this thing
    </button>
</div>
```

Want a vanilla flat icon button? Use `btn-plain`.

```html_example
<button class="btn-plain">
    <img src="icons/home.svg">
</button>
```

### Other Buttons

A few other default styles for your use.

```html_example
<div class="btn-inline-group">
<button class="btn-danger">Deleting Something</button>
<button class="btn-confirmation">Success!</button>
</div>
```

*/
.btn-plain, .btn-default, .ko-btn-default, .btn-white, .btn-primary, .btn-danger, .btn-confirmation, .btn-cancel,
.btn-text {
  color: #232A31;
  background-color: transparent;
  border: none;
  display: inline-block;
  font-size: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-weight: normal;
  position: relative;
  box-shadow: none;
  line-height: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }
  .btn-plain svg, .btn-default svg, .ko-btn-default svg, .btn-white svg, .btn-primary svg, .btn-danger svg, .btn-confirmation svg, .btn-cancel svg, .btn-text svg,
  .btn-plain path,
  .btn-default path,
  .ko-btn-default path,
  .btn-white path,
  .btn-primary path,
  .btn-danger path,
  .btn-confirmation path,
  .btn-cancel path,
  .btn-text path,
  .btn-plain polygon,
  .btn-default polygon,
  .ko-btn-default polygon,
  .btn-white polygon,
  .btn-primary polygon,
  .btn-danger polygon,
  .btn-confirmation polygon,
  .btn-cancel polygon,
  .btn-text polygon {
    width: 1rem;
    height: 1rem;
    fill: #8A8A8A; }
  .btn-inline-group .btn-plain, .btn-inline-group .btn-default, .btn-inline-group .ko-btn-default, .btn-inline-group .btn-white, .btn-inline-group .btn-primary, .btn-inline-group .btn-danger, .btn-inline-group .btn-confirmation, .btn-inline-group .btn-cancel,
  .btn-inline-group .btn-text {
    margin-left: .5rem; }
    .btn-inline-group .btn-plain:first-child, .btn-inline-group .btn-default:first-child, .btn-inline-group .ko-btn-default:first-child, .btn-inline-group .btn-white:first-child, .btn-inline-group .btn-primary:first-child, .btn-inline-group .btn-danger:first-child, .btn-inline-group .btn-confirmation:first-child, .btn-inline-group .btn-cancel:first-child,
    .btn-inline-group .btn-text:first-child {
      margin-left: 0; }

.btn-default, .ko-btn-default, .btn-white, .btn-primary, .btn-danger, .btn-confirmation, .btn-cancel,
.btn-text {
  font-weight: bold;
  font-family: trade;
  font-size: 1rem;
  padding: .75rem;
  border: 0.0625rem solid transparent;
  width: auto;
  vertical-align: top; }
  .btn-default svg, .ko-btn-default svg, .btn-white svg, .btn-primary svg, .btn-danger svg, .btn-confirmation svg, .btn-cancel svg, .btn-text svg {
    width: .75rem;
    height: .75rem;
    margin-right: .5rem; }

.btn-default, .ko-btn-default {
  box-shadow: 0 0.0625rem 0 0 #dddddd;
  border-radius: 0.125rem;
  border-color: #e9e9e9;
  border-style: solid;
  background-color: #F5F5F5;
  background-image: -webkit-linear-gradient(#F5F5F5, #f0f0f0);
  background-image: linear-gradient(#F5F5F5, #f0f0f0);
  color: #232A31; }
  .btn-default.btn-loading, .btn-loading.ko-btn-default {
    padding-left: 3rem;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .btn-default.btn-loading.btn-block, .btn-loading.btn-block.ko-btn-default {
      padding-left: .75rem; }
      .btn-default.btn-loading.btn-block:after, .btn-loading.btn-block.ko-btn-default:after {
        left: .75rem; }
    .btn-default.btn-loading img, .btn-loading.ko-btn-default img, .btn-default.btn-loading svg, .btn-loading.ko-btn-default svg {
      display: none; }
    .btn-default.btn-loading:disabled, .btn-loading.ko-btn-default:disabled,
    .btn-default.btn-loading .disabled,
    .btn-loading.ko-btn-default .disabled {
      opacity: 1; }
    .btn-default.btn-loading:after, .btn-loading.ko-btn-default:after {
      -webkit-animation: spinner-rotation 0.75s infinite linear;
      -moz-animation: spinner-rotation 0.75s infinite linear;
      animation: spinner-rotation 0.75s infinite linear;
      clear: both;
      height: 1rem;
      width: 1rem;
      margin: 0 auto 0 auto;
      position: relative;
      border-top: 0.25rem solid #8A8A8A;
      border-left: 0.25rem solid rgba(138, 138, 138, 0.4);
      border-bottom: 0.25rem solid rgba(138, 138, 138, 0.4);
      border-right: 0.25rem solid rgba(138, 138, 138, 0.4);
      border-radius: 100%;
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -.75rem;
      left: .75rem;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
      .no-cssanimations .btn-default.btn-loading:after, .no-cssanimations .btn-loading.ko-btn-default:after {
        display: none; }
  .btn-default svg, .ko-btn-default svg, .btn-default path, .ko-btn-default path, .btn-default polygon, .ko-btn-default polygon {
    fill: #232A31; }
  .btn-default:link, .ko-btn-default:link, .btn-default:visited, .ko-btn-default:visited {
    border-bottom: 0;
    color: #232A31; }
  .btn-default:disabled, .ko-btn-default:disabled, .btn-default.disabled, .disabled.ko-btn-default {
    color: #c2c2c2;
    opacity: 0.7;
    cursor: default; }
    .btn-default:disabled svg, .ko-btn-default:disabled svg, .btn-default:disabled path, .ko-btn-default:disabled path, .btn-default.disabled svg, .disabled.ko-btn-default svg, .btn-default.disabled path, .disabled.ko-btn-default path {
      fill: #c2c2c2; }
    .btn-default:disabled.btn-confirmation, .ko-btn-default:disabled.btn-confirmation, .btn-default.disabled.btn-confirmation, .disabled.btn-confirmation.ko-btn-default {
      color: #232A31; }
    .btn-default:disabled.btn-loading, .ko-btn-default:disabled.btn-loading, .btn-default.disabled.btn-loading, .disabled.btn-loading.ko-btn-default {
      color: #232A31; }
  .btn-default:hover, .ko-btn-default:hover, .btn-default:focus, .ko-btn-default:focus {
    background-color: #f8f8f8;
    background-image: none;
    outline: none; }
    .btn-default:hover:disabled, .ko-btn-default:hover:disabled, .btn-default:hover.disabled, .ko-btn-default:hover.disabled, .btn-default:focus:disabled, .ko-btn-default:focus:disabled, .btn-default:focus.disabled, .ko-btn-default:focus.disabled {
      background-color: #F5F5F5;
      background-image: -webkit-linear-gradient(#F5F5F5, #f0f0f0);
      background-image: linear-gradient(#F5F5F5, #f0f0f0); }
  .btn-default:active, .ko-btn-default:active {
    background-color: #f0f0f0;
    box-shadow: inset 0 0 0.25rem #e9e9e9;
    -webkit-transform: translateY(0.0625rem);
    -moz-transform: translateY(0.0625rem);
    -ms-transform: translateY(0.0625rem);
    -o-transform: translateY(0.0625rem);
    transform: translateY(0.0625rem); }
    .btn-default:active:disabled, .ko-btn-default:active:disabled, .btn-default:active.disabled, .ko-btn-default:active.disabled {
      box-shadow: 0 0.0625rem 0 0 #dddddd;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      background-color: #F5F5F5;
      background-image: -webkit-linear-gradient(#F5F5F5, #f0f0f0);
      background-image: linear-gradient(#F5F5F5, #f0f0f0); }

.btn-white {
  box-shadow: 0 0.0625rem 0 0 #e6e6e6;
  border-radius: 0.125rem;
  border-color: #f2f2f2;
  border-style: solid;
  background-color: #FFFFFF;
  background-image: -webkit-linear-gradient(#FFFFFF, #fafafa);
  background-image: linear-gradient(#FFFFFF, #fafafa);
  color: #232A31; }
  .btn-white.btn-loading {
    padding-left: 3rem;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .btn-white.btn-loading.btn-block {
      padding-left: .75rem; }
      .btn-white.btn-loading.btn-block:after {
        left: .75rem; }
    .btn-white.btn-loading img, .btn-white.btn-loading svg {
      display: none; }
    .btn-white.btn-loading:disabled,
    .btn-white.btn-loading .disabled {
      opacity: 1; }
    .btn-white.btn-loading:after {
      -webkit-animation: spinner-rotation 0.75s infinite linear;
      -moz-animation: spinner-rotation 0.75s infinite linear;
      animation: spinner-rotation 0.75s infinite linear;
      clear: both;
      height: 1rem;
      width: 1rem;
      margin: 0 auto 0 auto;
      position: relative;
      border-top: 0.25rem solid #8A8A8A;
      border-left: 0.25rem solid rgba(138, 138, 138, 0.4);
      border-bottom: 0.25rem solid rgba(138, 138, 138, 0.4);
      border-right: 0.25rem solid rgba(138, 138, 138, 0.4);
      border-radius: 100%;
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -.75rem;
      left: .75rem;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
      .no-cssanimations .btn-white.btn-loading:after {
        display: none; }
  .btn-white svg, .btn-white path, .btn-white polygon {
    fill: #232A31; }
  .btn-white:link, .btn-white:visited {
    border-bottom: 0;
    color: #232A31; }
  .btn-white:disabled, .btn-white.disabled {
    color: #cccccc;
    opacity: 0.7;
    cursor: default; }
    .btn-white:disabled svg, .btn-white:disabled path, .btn-white.disabled svg, .btn-white.disabled path {
      fill: #cccccc; }
    .btn-white:disabled.btn-confirmation, .btn-white.disabled.btn-confirmation {
      color: #232A31; }
    .btn-white:disabled.btn-loading, .btn-white.disabled.btn-loading {
      color: #232A31; }
  .btn-white:hover, .btn-white:focus {
    background-color: white;
    background-image: none;
    outline: none; }
    .btn-white:hover:disabled, .btn-white:hover.disabled, .btn-white:focus:disabled, .btn-white:focus.disabled {
      background-color: #FFFFFF;
      background-image: -webkit-linear-gradient(#FFFFFF, #fafafa);
      background-image: linear-gradient(#FFFFFF, #fafafa); }
  .btn-white:active {
    background-color: #fafafa;
    box-shadow: inset 0 0 0.25rem #f2f2f2;
    -webkit-transform: translateY(0.0625rem);
    -moz-transform: translateY(0.0625rem);
    -ms-transform: translateY(0.0625rem);
    -o-transform: translateY(0.0625rem);
    transform: translateY(0.0625rem); }
    .btn-white:active:disabled, .btn-white:active.disabled {
      box-shadow: 0 0.0625rem 0 0 #e6e6e6;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      background-color: #FFFFFF;
      background-image: -webkit-linear-gradient(#FFFFFF, #fafafa);
      background-image: linear-gradient(#FFFFFF, #fafafa); }

.btn-primary {
  box-shadow: 0 0.0625rem 0 0 #e65c00;
  border-radius: 0.125rem;
  border-color: #f26100;
  border-style: solid;
  background-color: #FF6600;
  background-image: -webkit-linear-gradient(#FF6600, #fa6400);
  background-image: linear-gradient(#FF6600, #fa6400);
  color: #FFFFFF; }
  .btn-primary.btn-loading {
    padding-left: 3rem;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .btn-primary.btn-loading.btn-block {
      padding-left: .75rem; }
      .btn-primary.btn-loading.btn-block:after {
        left: .75rem; }
    .btn-primary.btn-loading img, .btn-primary.btn-loading svg {
      display: none; }
    .btn-primary.btn-loading:disabled,
    .btn-primary.btn-loading .disabled {
      opacity: 1; }
    .btn-primary.btn-loading:after {
      -webkit-animation: spinner-rotation 0.75s infinite linear;
      -moz-animation: spinner-rotation 0.75s infinite linear;
      animation: spinner-rotation 0.75s infinite linear;
      clear: both;
      height: 1rem;
      width: 1rem;
      margin: 0 auto 0 auto;
      position: relative;
      border-top: 0.25rem solid #FFFFFF;
      border-left: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-bottom: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-right: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-radius: 100%;
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -.75rem;
      left: .75rem;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
      .no-cssanimations .btn-primary.btn-loading:after {
        display: none; }
  .btn-primary svg, .btn-primary path, .btn-primary polygon {
    fill: #FFFFFF; }
  .btn-primary:link, .btn-primary:visited {
    color: #FFFFFF; }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #ffa366;
    opacity: 0.4;
    cursor: default; }
    .btn-primary:disabled svg, .btn-primary:disabled path, .btn-primary.disabled svg, .btn-primary.disabled path {
      fill: #ffa366; }
    .btn-primary:disabled.btn-confirmation, .btn-primary.disabled.btn-confirmation {
      color: #FFFFFF; }
    .btn-primary:disabled.btn-loading, .btn-primary.disabled.btn-loading {
      color: #FFFFFF; }
  .btn-primary:hover, .btn-primary:focus {
    background: #ff751a;
    background-image: none;
    outline: none; }
    .btn-primary:hover:disabled, .btn-primary:hover.disabled, .btn-primary:focus:disabled, .btn-primary:focus.disabled {
      background-color: #FF6600;
      background-image: -webkit-linear-gradient(#FF6600, #fa6400);
      background-image: linear-gradient(#FF6600, #fa6400); }
  .btn-primary:active {
    background-color: #f56200;
    box-shadow: inset 0 0 0.25rem #f26100;
    -webkit-transform: translateY(0.0625rem);
    -moz-transform: translateY(0.0625rem);
    -ms-transform: translateY(0.0625rem);
    -o-transform: translateY(0.0625rem);
    transform: translateY(0.0625rem); }
    .btn-primary:active:disabled, .btn-primary:active.disabled {
      box-shadow: 0 0.0625rem 0 0 #e65c00;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      background-color: #FF6600;
      background-image: -webkit-linear-gradient(#FF6600, #fa6400);
      background-image: linear-gradient(#FF6600, #fa6400); }

.btn-danger {
  box-shadow: 0 0.0625rem 0 0 #c21a1a;
  border-radius: 0.125rem;
  border-color: #cd1c1c;
  border-style: solid;
  background-color: #D81D1D;
  background-image: -webkit-linear-gradient(#D81D1D, #d41c1c);
  background-image: linear-gradient(#D81D1D, #d41c1c);
  color: #FFFFFF; }
  .btn-danger.btn-loading {
    padding-left: 3rem;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .btn-danger.btn-loading.btn-block {
      padding-left: .75rem; }
      .btn-danger.btn-loading.btn-block:after {
        left: .75rem; }
    .btn-danger.btn-loading img, .btn-danger.btn-loading svg {
      display: none; }
    .btn-danger.btn-loading:disabled,
    .btn-danger.btn-loading .disabled {
      opacity: 1; }
    .btn-danger.btn-loading:after {
      -webkit-animation: spinner-rotation 0.75s infinite linear;
      -moz-animation: spinner-rotation 0.75s infinite linear;
      animation: spinner-rotation 0.75s infinite linear;
      clear: both;
      height: 1rem;
      width: 1rem;
      margin: 0 auto 0 auto;
      position: relative;
      border-top: 0.25rem solid #FFFFFF;
      border-left: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-bottom: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-right: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-radius: 100%;
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -.75rem;
      left: .75rem;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
      .no-cssanimations .btn-danger.btn-loading:after {
        display: none; }
  .btn-danger svg, .btn-danger path, .btn-danger polygon {
    fill: #FFFFFF; }
  .btn-danger:link, .btn-danger:visited {
    color: #FFFFFF; }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #ec6f6f;
    opacity: 0.4;
    cursor: default; }
    .btn-danger:disabled svg, .btn-danger:disabled path, .btn-danger.disabled svg, .btn-danger.disabled path {
      fill: #ec6f6f; }
    .btn-danger:disabled.btn-confirmation, .btn-danger.disabled.btn-confirmation {
      color: #FFFFFF; }
    .btn-danger:disabled.btn-loading, .btn-danger.disabled.btn-loading {
      color: #FFFFFF; }
  .btn-danger:hover, .btn-danger:focus {
    background: #e32c2c;
    background-image: none;
    outline: none; }
    .btn-danger:hover:disabled, .btn-danger:hover.disabled, .btn-danger:focus:disabled, .btn-danger:focus.disabled {
      background-color: #D81D1D;
      background-image: -webkit-linear-gradient(#D81D1D, #d41c1c);
      background-image: linear-gradient(#D81D1D, #d41c1c); }
  .btn-danger:active {
    background-color: #cf1c1c;
    box-shadow: inset 0 0 0.25rem #cd1c1c;
    -webkit-transform: translateY(0.0625rem);
    -moz-transform: translateY(0.0625rem);
    -ms-transform: translateY(0.0625rem);
    -o-transform: translateY(0.0625rem);
    transform: translateY(0.0625rem); }
    .btn-danger:active:disabled, .btn-danger:active.disabled {
      box-shadow: 0 0.0625rem 0 0 #c21a1a;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      background-color: #D81D1D;
      background-image: -webkit-linear-gradient(#D81D1D, #d41c1c);
      background-image: linear-gradient(#D81D1D, #d41c1c); }

.btn-confirmation {
  box-shadow: 0 0.0625rem 0 0 #5f921d;
  border-radius: 0.125rem;
  border-color: #659a1e;
  border-style: solid;
  background-color: #6AA220;
  background-image: -webkit-linear-gradient(#6AA220, #689f1f);
  background-image: linear-gradient(#6AA220, #689f1f);
  color: #FFFFFF; }
  .btn-confirmation.btn-loading {
    padding-left: 3rem;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .btn-confirmation.btn-loading.btn-block {
      padding-left: .75rem; }
      .btn-confirmation.btn-loading.btn-block:after {
        left: .75rem; }
    .btn-confirmation.btn-loading img, .btn-confirmation.btn-loading svg {
      display: none; }
    .btn-confirmation.btn-loading:disabled,
    .btn-confirmation.btn-loading .disabled {
      opacity: 1; }
    .btn-confirmation.btn-loading:after {
      -webkit-animation: spinner-rotation 0.75s infinite linear;
      -moz-animation: spinner-rotation 0.75s infinite linear;
      animation: spinner-rotation 0.75s infinite linear;
      clear: both;
      height: 1rem;
      width: 1rem;
      margin: 0 auto 0 auto;
      position: relative;
      border-top: 0.25rem solid #FFFFFF;
      border-left: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-bottom: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-right: 0.25rem solid rgba(255, 255, 255, 0.4);
      border-radius: 100%;
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -.75rem;
      left: .75rem;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition: 0.2s; }
      .no-cssanimations .btn-confirmation.btn-loading:after {
        display: none; }
  .btn-confirmation svg, .btn-confirmation path, .btn-confirmation polygon {
    fill: #FFFFFF; }
  .btn-confirmation:link, .btn-confirmation:visited {
    color: #FFFFFF; }
  .btn-confirmation:disabled, .btn-confirmation.disabled {
    color: #9edc4c;
    opacity: 0.4;
    cursor: default; }
    .btn-confirmation:disabled svg, .btn-confirmation:disabled path, .btn-confirmation.disabled svg, .btn-confirmation.disabled path {
      fill: #9edc4c; }
    .btn-confirmation:disabled.btn-confirmation, .btn-confirmation.disabled.btn-confirmation {
      color: #FFFFFF; }
    .btn-confirmation:disabled.btn-loading, .btn-confirmation.disabled.btn-loading {
      color: #FFFFFF; }
  .btn-confirmation:hover, .btn-confirmation:focus {
    background: #78b724;
    background-image: none;
    outline: none; }
    .btn-confirmation:hover:disabled, .btn-confirmation:hover.disabled, .btn-confirmation:focus:disabled, .btn-confirmation:focus.disabled {
      background-color: #6AA220;
      background-image: -webkit-linear-gradient(#6AA220, #689f1f);
      background-image: linear-gradient(#6AA220, #689f1f); }
  .btn-confirmation:active {
    background-color: #64991e;
    box-shadow: inset 0 0 0.25rem #659a1e;
    -webkit-transform: translateY(0.0625rem);
    -moz-transform: translateY(0.0625rem);
    -ms-transform: translateY(0.0625rem);
    -o-transform: translateY(0.0625rem);
    transform: translateY(0.0625rem); }
    .btn-confirmation:active:disabled, .btn-confirmation:active.disabled {
      box-shadow: 0 0.0625rem 0 0 #5f921d;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      background-color: #6AA220;
      background-image: -webkit-linear-gradient(#6AA220, #689f1f);
      background-image: linear-gradient(#6AA220, #689f1f); }
  .btn-confirmation:disabled, .btn-confirmation.disabled {
    opacity: 1; }

.btn-cancel,
.btn-text {
  color: #FF6600;
  font-weight: bold; }
  .btn-cancel:hover,
  .btn-text:hover {
    background-color: transparent;
    color: #ff751a; }

.btn-block {
  text-align: center;
  display: block;
  width: 100%; }

/*doc
---
title: Article
name: article
category: Elements
---

Since Hudl is a web app, we're confining default typography to within a `.ko-article` class. We've got default stylings for headings, paragraphs, links, and lists. Everything also gets a line-height of 1.4 by default and solid vertical rhythm.

```html_example
<div class="ko-article">
    <h1>This is a big heading.</h1>
    <p>Pede cum deserunt ducimus <a href="#">hymenaeos gravida risus sodales</a> molestias repellendus nostrud ipsam, praesent nam temporibus praesent aspernatur turpis, ridiculus, condimentum? Nullam sed animi adipisci veniam. Iure sociosqu, <strong>possimus phasellus vehicula</strong>, amet erat eaque, veritatis ea lacus dolorum pharetra! Hic quas? Quis alias. Cillum habitasse. Nostrum semper? Metus ea, maecenas fames.</p>
    <h2>This is a smaller heading.</h2>
    <p>Egestas conubia velit senectus eligendi tortor nobis qui nostrud itaque mollitia. Iure ex incididunt varius quas mauris! Minima rhoncus magni! <em>Minus sit mollis cras, pariatur tortor dicta accusamus temporibus mauris nibh molestie</em>, sapiente pretium luctus! Mattis magnis purus assumenda dictum quis interdum atque! Cupiditate. Adipisicing, quisquam sem lobortis porta. Inventore.</p>
    <ul>
        <li>I don't know what this list is about.</li>
        <li>It could be about anything, really.</li>
        <li>This third point is pretty convincing.</li>
    </ul>
    <h3>Another heading! Whoo hoo!</h3>
    <p>Torquent! Rutrum nunc quaerat dignissimos? Occaecat, volutpat soluta aute nemo aut wisi? Architecto temporibus eaque, doloremque harum exercitation! Urna, vestibulum dolorem eligendi, diam perspiciatis. Maecenas? Optio cubilia quisque, ut ullamco, libero libero.</p>
    <p>Ut optio perspiciatis esse aut, quia soluta laoreet, facilis delectus. Sollicitudin non sodales taciti dapibus, expedita! Magnam ipsam, dictumst natus, pulvinar proident sequi! Totam excepteur.</p>
    <h4>We've got H4 styles too!</h4>
    <ol>
        <li>How about some numbered lists?</li>
        <li>You can use these when order is important.</li>
        <li>Like ranking your favorite athletes.</li>
    </ol>
</div>
```

*/
.ko-article {
  line-height: 1.4rem; }
  .ko-article h1, .ko-article h2, .ko-article h3, .ko-article h4, .ko-article h5, .ko-article h6 {
    font-weight: bold;
    margin-bottom: 1.4rem;
    line-height: 1.4rem; }
  .ko-article h1 {
    text-transform: uppercase;
    line-height: 2.8rem;
    font-size: 3rem; }
  .ko-article h2 {
    line-height: 2.8rem;
    font-size: 2rem; }
  .ko-article h3 {
    font-size: 1.5rem; }
  .ko-article h4 {
    font-size: 1.3125rem; }
  .ko-article h5, .ko-article h6 {
    font-size: 1.125rem; }
  .ko-article p {
    margin-bottom: 1.4rem; }
  .ko-article strong, .ko-article b {
    font-weight: bold; }
  .ko-article em, .ko-article i {
    font-style: italic; }
  .ko-article ul, .ko-article ol {
    margin-bottom: 1.4rem;
    margin-left: 2.8rem; }
  .ko-article ul {
    list-style-type: disc; }
  .ko-article ol {
    list-style-type: decimal; }
  .ko-article code {
    background-color: #E8E8E8;
    border-radius: .125rem;
    font-weight: bold;
    display: inline-block;
    font-family: "Source Code Pro";
    padding: .125rem;
    font-size: 0.75rem;
    line-height: 1; }

/*doc
---
title: Forms
name: forms
category: Elements
---

We use the [float label](http://bradfrostweb.com/blog/post/float-label-pattern/) pattern around here. For each item, put a `<div>` around two things: a `<label>` and an `<input>`. Give the `<div>` a class of `ko-field` and you're good to go. Remember to use fancy HTML5 form fields!

The JS included with kickoff is smart enough to handle the labels and degrade gracefully (e.g., in browsers that don't support placeholders, the labels are always present).

```html_example
<div class="ko-field">
    <label>Name</label>
    <input type="text" placeholder="Name">
</div>
<div class="ko-field">
    <label>Email Address</label>
    <input type="email" placeholder="Email Address">
</div>
<div class="ko-field">
    <label>Your Message</label>
    <textarea placeholder="Leave us a message"></textarea>
</div>
```

### Form Validation

Throw a class of `ko-invalid` on the `<label>` and the `<input>` to get an invalid style and use `ko-valid` if you want an explicit valid style. Use the `<label>` for your error/success message.

```html_example
<div class="ko-field">
    <label class="ko-invalid">Not quite right</label>
    <input type="email" placeholder="Email Address" class="ko-invalid">
</div>
<div class="ko-field">
    <label class="ko-valid">Looks good!</label>
    <input type="email" placeholder="Email Address" class="ko-valid">
</div>
```

### Many column'd forms

You can combine fields with Bourbon Neat to make a nice form layout. We've got built-in classes for a few widths based on our default 12 column grid, but feel free to add your own custom widths or classes. `ko-field-wrapper` is a clearfix'd container.

```html_example
<div class="ko-field-wrapper">
    <div class="ko-field ko-field-half">
        <label>Name</label>
        <input type="text" placeholder="Name">
    </div>
    <div class="ko-field ko-field-half">
        <label>Email Address</label>
        <input type="email" placeholder="Email Address">
    </div>
</div>
```

```html_example
<div class="ko-field-wrapper">
    <div class="ko-field ko-field-third">
        <label>Name</label>
        <input type="text" placeholder="Name">
    </div>
    <div class="ko-field ko-field-third">
        <label>Email</label>
        <input type="email" placeholder="Email">
    </div>
    <div class="ko-field ko-field-third">
        <label>Phone</label>
        <input type="tel" placeholder="Phone">
    </div>
</div>
```

```html_example
<div class="ko-field-wrapper">
    <div class="ko-field ko-field-fourth">
        <label>Name</label>
        <input type="text" placeholder="Name">
    </div>
    <div class="ko-field ko-field-fourth">
        <label>Email</label>
        <input type="email" placeholder="Email">
    </div>
    <div class="ko-field ko-field-fourth">
        <label>Phone</label>
        <input type="tel" placeholder="Phone">
    </div>
    <div class="ko-field ko-field-fourth">
        <label>Twitter Handle</label>
        <input type="text" placeholder="Instagram">
    </div>
</div>
```

### Form Actions

Put your buttons at the bottom of the form wrapped in a `<div>` with class `ko-form-actions`.

```html_example
<div class="ko-field">
    <label>Name</label>
    <input type="text" placeholder="Name">
</div>
<div class="ko-field">
    <label>Email Address</label>
    <input type="email" placeholder="Email Address">
</div>
<div class="ko-form-actions">
    <button class="btn-primary">Add this Athlete</button>
    <button class="btn-cancel">Cancel</button>
</div>
```

### Checkboxes and Radios

As of right now, checkboxes and radios are **not** native `<input>` elements.

To get radios working like radios, you'll want to add:

```
    $('.ko-radios li').click(function(){
        if ($(this).hasClass('disabled')) return;

        $(this).siblings().removeClass('ko-checked');
        $(this).addClass('ko-checked');
    });

```

To get checkboxes working like checkboxes, you'll want to add:

```
    $('.ko-checkboxes li').each(function(){
        $(this).find('.ko-choice-mark').html('<svg viewBox="0 0 612 792"><polygon points="0,416.4 81.6,334.8 211,472 510,151.2 612,232.8 204,640.8 "/></svg>');
    });

    $('.ko-checkboxes li').click(function(){
        if ($(this).hasClass('disabled')) return;

        $(this).toggleClass('ko-checked');
    });

```

They look like this:

```html_example
<ul class="ko-radios">
    <li>
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">Choose this one</span>
    </li>
    <li>
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">Or maybe this</span>
    </li>
    <li>
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">Perhaps this is better</span>
    </li>
    <li class="disabled">
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">But this one's worse</span>
    </li>
</ul>
```

```html_example
<ul class="ko-checkboxes">
    <li>
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">You can choose</span>
    </li>
    <li>
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">As many things</span>
    </li>
    <li>
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">As you'd like!</span>
    </li>
    <li class="disabled">
        <span class="ko-choice-mark"></span>
        <span class="ko-choice-label">Except this thing</span>
    </li>
</ul>
```

*/
.ko-field-wrapper:after {
  content: "";
  display: table;
  clear: both; }

.ko-field {
  position: relative;
  margin-top: 1.6rem; }
  .ko-field:after {
    content: "";
    display: table;
    clear: both; }
  .ko-field.ko-field-half {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
    .ko-field.ko-field-half:last-child {
      margin-right: 0; }
  .ko-field.ko-field-third {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
    .ko-field.ko-field-third:last-child {
      margin-right: 0; }
  .ko-field.ko-field-fourth {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%; }
    .ko-field.ko-field-fourth:last-child {
      margin-right: 0; }
  .ko-field:last-child {
    margin-bottom: 0; }
  .ko-field input[type="text"],
  .ko-field input[type="email"],
  .ko-field input[type="password"],
  .ko-field input[type="number"],
  .ko-field input[type="search"],
  .ko-field input[type="date"],
  .ko-field input[type="time"],
  .ko-field input[type="tel"],
  .ko-field select,
  .ko-field textarea {
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    margin: 0;
    padding: 0;
    font-family: trade;
    background-color: #FFFFFF;
    font-size: 1rem;
    color: #232A31;
    display: block;
    width: 100%;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 1px;
    border-radius: 2px;
    padding: 1rem;
    -webkit-transition: border-color 0.2s;
    -moz-transition: border-color 0.2s;
    transition: border-color 0.2s; }
    .ko-field input[type="text"]::-webkit-search-decoration,
    .ko-field input[type="email"]::-webkit-search-decoration,
    .ko-field input[type="password"]::-webkit-search-decoration,
    .ko-field input[type="number"]::-webkit-search-decoration,
    .ko-field input[type="search"]::-webkit-search-decoration,
    .ko-field input[type="date"]::-webkit-search-decoration,
    .ko-field input[type="time"]::-webkit-search-decoration,
    .ko-field input[type="tel"]::-webkit-search-decoration,
    .ko-field select::-webkit-search-decoration,
    .ko-field textarea::-webkit-search-decoration {
      -webkit-appearance: none; }
    .ko-field input[type="text"]::-webkit-input-placeholder,
    .ko-field input[type="email"]::-webkit-input-placeholder,
    .ko-field input[type="password"]::-webkit-input-placeholder,
    .ko-field input[type="number"]::-webkit-input-placeholder,
    .ko-field input[type="search"]::-webkit-input-placeholder,
    .ko-field input[type="date"]::-webkit-input-placeholder,
    .ko-field input[type="time"]::-webkit-input-placeholder,
    .ko-field input[type="tel"]::-webkit-input-placeholder,
    .ko-field select::-webkit-input-placeholder,
    .ko-field textarea::-webkit-input-placeholder {
      color: #8A8A8A; }
    .ko-field input[type="text"]::-webkit-inner-spin-button, .ko-field input[type="text"]::-webkit-outer-spin-button,
    .ko-field input[type="email"]::-webkit-inner-spin-button,
    .ko-field input[type="email"]::-webkit-outer-spin-button,
    .ko-field input[type="password"]::-webkit-inner-spin-button,
    .ko-field input[type="password"]::-webkit-outer-spin-button,
    .ko-field input[type="number"]::-webkit-inner-spin-button,
    .ko-field input[type="number"]::-webkit-outer-spin-button,
    .ko-field input[type="search"]::-webkit-inner-spin-button,
    .ko-field input[type="search"]::-webkit-outer-spin-button,
    .ko-field input[type="date"]::-webkit-inner-spin-button,
    .ko-field input[type="date"]::-webkit-outer-spin-button,
    .ko-field input[type="time"]::-webkit-inner-spin-button,
    .ko-field input[type="time"]::-webkit-outer-spin-button,
    .ko-field input[type="tel"]::-webkit-inner-spin-button,
    .ko-field input[type="tel"]::-webkit-outer-spin-button,
    .ko-field select::-webkit-inner-spin-button,
    .ko-field select::-webkit-outer-spin-button,
    .ko-field textarea::-webkit-inner-spin-button,
    .ko-field textarea::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .ko-field input[type="text"]::-moz-placeholder,
    .ko-field input[type="email"]::-moz-placeholder,
    .ko-field input[type="password"]::-moz-placeholder,
    .ko-field input[type="number"]::-moz-placeholder,
    .ko-field input[type="search"]::-moz-placeholder,
    .ko-field input[type="date"]::-moz-placeholder,
    .ko-field input[type="time"]::-moz-placeholder,
    .ko-field input[type="tel"]::-moz-placeholder,
    .ko-field select::-moz-placeholder,
    .ko-field textarea::-moz-placeholder {
      /* Firefox 19+ */
      color: #8A8A8A; }
    .ko-field input[type="text"]:-ms-input-placeholder,
    .ko-field input[type="email"]:-ms-input-placeholder,
    .ko-field input[type="password"]:-ms-input-placeholder,
    .ko-field input[type="number"]:-ms-input-placeholder,
    .ko-field input[type="search"]:-ms-input-placeholder,
    .ko-field input[type="date"]:-ms-input-placeholder,
    .ko-field input[type="time"]:-ms-input-placeholder,
    .ko-field input[type="tel"]:-ms-input-placeholder,
    .ko-field select:-ms-input-placeholder,
    .ko-field textarea:-ms-input-placeholder {
      color: #8A8A8A; }
    .ko-field input[type="text"]:focus, .ko-field input[type="text"]:active,
    .ko-field input[type="email"]:focus,
    .ko-field input[type="email"]:active,
    .ko-field input[type="password"]:focus,
    .ko-field input[type="password"]:active,
    .ko-field input[type="number"]:focus,
    .ko-field input[type="number"]:active,
    .ko-field input[type="search"]:focus,
    .ko-field input[type="search"]:active,
    .ko-field input[type="date"]:focus,
    .ko-field input[type="date"]:active,
    .ko-field input[type="time"]:focus,
    .ko-field input[type="time"]:active,
    .ko-field input[type="tel"]:focus,
    .ko-field input[type="tel"]:active,
    .ko-field select:focus,
    .ko-field select:active,
    .ko-field textarea:focus,
    .ko-field textarea:active {
      border-color: #FF6600;
      box-shadow: none;
      outline: none; }
      .ko-field input[type="text"]:focus.ko-valid, .ko-field input[type="text"]:active.ko-valid,
      .ko-field input[type="email"]:focus.ko-valid,
      .ko-field input[type="email"]:active.ko-valid,
      .ko-field input[type="password"]:focus.ko-valid,
      .ko-field input[type="password"]:active.ko-valid,
      .ko-field input[type="number"]:focus.ko-valid,
      .ko-field input[type="number"]:active.ko-valid,
      .ko-field input[type="search"]:focus.ko-valid,
      .ko-field input[type="search"]:active.ko-valid,
      .ko-field input[type="date"]:focus.ko-valid,
      .ko-field input[type="date"]:active.ko-valid,
      .ko-field input[type="time"]:focus.ko-valid,
      .ko-field input[type="time"]:active.ko-valid,
      .ko-field input[type="tel"]:focus.ko-valid,
      .ko-field input[type="tel"]:active.ko-valid,
      .ko-field select:focus.ko-valid,
      .ko-field select:active.ko-valid,
      .ko-field textarea:focus.ko-valid,
      .ko-field textarea:active.ko-valid {
        border-color: #6AA220; }
    .ko-field input[type="text"]:invalid,
    .ko-field input[type="email"]:invalid,
    .ko-field input[type="password"]:invalid,
    .ko-field input[type="number"]:invalid,
    .ko-field input[type="search"]:invalid,
    .ko-field input[type="date"]:invalid,
    .ko-field input[type="time"]:invalid,
    .ko-field input[type="tel"]:invalid,
    .ko-field select:invalid,
    .ko-field textarea:invalid {
      box-shadow: none; }
    .ko-field input[type="text"].ko-invalid,
    .ko-field input[type="email"].ko-invalid,
    .ko-field input[type="password"].ko-invalid,
    .ko-field input[type="number"].ko-invalid,
    .ko-field input[type="search"].ko-invalid,
    .ko-field input[type="date"].ko-invalid,
    .ko-field input[type="time"].ko-invalid,
    .ko-field input[type="tel"].ko-invalid,
    .ko-field select.ko-invalid,
    .ko-field textarea.ko-invalid {
      border-color: #D81D1D; }
    .ko-field input[type="text"].ko-valid,
    .ko-field input[type="email"].ko-valid,
    .ko-field input[type="password"].ko-valid,
    .ko-field input[type="number"].ko-valid,
    .ko-field input[type="search"].ko-valid,
    .ko-field input[type="date"].ko-valid,
    .ko-field input[type="time"].ko-valid,
    .ko-field input[type="tel"].ko-valid,
    .ko-field select.ko-valid,
    .ko-field textarea.ko-valid {
      border-color: #6AA220; }
  .ko-field label {
    font-weight: bold;
    font-family: trade;
    text-transform: uppercase;
    color: #FF6600;
    left: 0;
    opacity: 0;
    display: block;
    position: absolute;
    bottom: 100%;
    width: 100%;
    display: inline-block;
    padding: .1rem 1rem;
    line-height: 1rem;
    font-size: 0.75rem;
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .no-placeholder .ko-field label {
      opacity: 1; }
    .no-csstransitions .ko-field label {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    .ko-field label.ko-active {
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
      .ko-field label.ko-active.ko-valid {
        color: #6AA220;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); }
    .ko-field label.ko-invalid {
      opacity: 1;
      color: #D81D1D;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    .ko-field label.ko-valid {
      color: #6AA220;
      opacity: 1;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
  .ko-field select {
    cursor: pointer;
    background-color: #F5F5F5;
    background-image: -webkit-linear-gradient(#F5F5F5, #ededed);
    background-image: linear-gradient(#F5F5F5, #ededed); }

.ko-form-actions {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem; }

@-webkit-keyframes check-it {
  0% {
    -webkit-transform: scale(1); }
  50% {
    -webkit-transform: scale(1.25);
    -webkit-animation-timing-function: ease; }
  100% {
    -webkit-transform: scale(1);
    -webkit-animation-timing-function: ease-out; } }

@-moz-keyframes check-it {
  0% {
    -moz-transform: scale(1); }
  50% {
    -moz-transform: scale(1.25);
    -moz-animation-timing-function: ease; }
  100% {
    -moz-transform: scale(1);
    -moz-animation-timing-function: ease-out; } }

@keyframes check-it {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    animation-timing-function: ease; }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

ul.ko-checkboxes, ul.ko-radios {
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none; }
  ul.ko-checkboxes li, ul.ko-radios li {
    margin: 0;
    cursor: pointer;
    margin-bottom: 1rem;
    line-height: 1.5rem; }

ul.ko-checkboxes li:hover .ko-choice-mark svg {
  fill: #E8E8E8; }

ul.ko-checkboxes li.disabled:hover .ko-choice-mark svg {
  fill: transparent; }

ul.ko-checkboxes li.ko-checked:hover .ko-choice-mark svg {
  fill: #FF6600; }

ul.ko-radios li:hover .ko-choice-mark:after {
  background-color: #E8E8E8; }

ul.ko-radios li.disabled:hover .ko-choice-mark:after {
  background-color: transparent; }

ul.ko-radios li.ko-checked:hover .ko-choice-mark:after {
  background-color: #FF6600; }

.disabled .ko-choice-label {
  color: #8A8A8A; }

.ko-choice-mark {
  position: relative;
  border: 0.125rem solid #FF6600;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .4rem;
  display: inline-block;
  vertical-align: bottom;
  background-color: #FFFFFF;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s; }
  .ko-choice-mark:after {
    content: '';
    position: absolute;
    top: 10%;
    left: 10%;
    background-color: transparent;
    height: 80%;
    width: 80%;
    border-radius: 50%;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .disabled .ko-choice-mark {
    border: 0.125rem solid #E8E8E8; }
  .ko-radios .ko-choice-mark {
    border-radius: 50%; }
  .ko-checked .ko-choice-mark {
    -webkit-animation: check-it 0.2s forwards;
    -moz-animation: check-it 0.2s forwards;
    animation: check-it 0.2s forwards; }
    .ko-radios .ko-checked .ko-choice-mark:after {
      background-color: #FF6600;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
  .ko-choice-mark svg {
    width: 100%;
    height: 100%;
    fill: transparent;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s; }
    .ko-checked .ko-choice-mark svg {
      fill: #FF6600; }

/*doc
---
title: Links
name: links
category: Elements
---

Throw a class of `ko-link` on any link to give it a nice format.

Links inside of paragraphs and lists inside of the `ko-article` class get this treatment by default.

```html_example
<div class="ko-article">
    <p>Eleifend eaque quo semper <a href="#">pellentesque laboriosam habitasse</a>, asperiores ultrices condimentum netus, rerum?</p>
</div>

<div class="widget">
    Habitasse sequi <a class="ko-link" href="#">varius tellus natoque excepturi</a>! Atque in quam officiis nunc vestibulum tellus augue praesentium temporibus.
</div>
```

*/
.ko-link, .ko-article p a, .ko-article ul a, .ko-article ol a {
  font-weight: bold;
  color: #FF6600;
  border-bottom: 1px solid #E8E8E8;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s; }
  .ko-link:hover, .ko-article p a:hover, .ko-article ul a:hover, .ko-article ol a:hover {
    color: #e65c00;
    border-color: #FF6600; }
  .ko-link:active, .ko-article p a:active, .ko-article ul a:active, .ko-article ol a:active, .ko-link:focus, .ko-article p a:focus, .ko-article ul a:focus, .ko-article ol a:focus {
    color: #ff751a; }

.dropmenu {
  border: solid 1px #969696;
  background-color: #FFFFFF;
  height: 16px;
  cursor: pointer;
  line-height: 16px; }

input.dropmenu {
  cursor: text; }

.dropDownButton {
  width: 16px;
  line-height: 16px;
  height: 16px;
  cursor: pointer;
  float: right;
  background: transparent url(/images/dropdown.gif) no-repeat; }

.dropmenu p {
  float: left;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer; }

div.dropDown {
  background-color: #FFFFFF;
  padding: 0px;
  margin: 0px;
  border: solid 1px #6b6b6b;
  max-height: 230px;
  overflow-x: auto;
  overflow-y: auto;
  z-index: 1;
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5); }

ul.menu {
  list-style-type: none;
  background-color: #FFFFFF; }

ul.menu li {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden; }

ul.menu li:hover {
  color: #FFFFFF;
  background-color: #FF6600; }

ul.menu li.selected {
  background-color: #0099ff;
  color: #FFFFFF; }

div.user-list {
  width: 152px;
  border: solid 1px #969696;
  background-color: #FFFFFF; }

/* lyle.shearer 1/27/2012 - TODO I think this is duplicated in core.css */
.scroll {
  overflow-x: hidden;
  overflow-y: auto; }

ul.users, ul.columns {
  list-style-type: none;
  overflow: hidden; }

ul.users li {
  cursor: pointer;
  height: 14px;
  text-indent: 20px;
  overflow: hidden;
  white-space: nowrap; }

ul.sl {
  min-height: 170px; }

ul.sl li.hover {
  background-color: #E8E8E8; }

ul.sl li.selected {
  background-color: #0099FF;
  color: #FFFFFF; }

li.sl.selected {
  background-color: #0099FF;
  color: #FFFFFF; }

li.sl {
  cursor: pointer;
  height: 14px;
  text-indent: 5px;
  overflow: hidden;
  white-space: nowrap; }

.sl-empty-text {
  color: #B4B4B4;
  position: absolute;
  top: 0;
  font-size: 14px;
  margin: 155px 10px 0 10px;
  text-align: center; }

li.with-icon {
  text-indent: 20px; }

li.g, #accessList li.g label {
  background-image: url(/images/group16.png);
  background-repeat: no-repeat; }

#accessList li.g, #accessList li.u {
  background: transparent; }

li.u, #accessList li.u label {
  background-image: url(/images/user16.png);
  background-repeat: no-repeat; }

ul.columns li {
  cursor: pointer;
  height: 14px;
  overflow: hidden; }

ul.buttons {
  list-style-type: none;
  font-size: 10px;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: bold; }

ul.buttons li {
  display: block;
  margin: 2px 0;
  text-align: center;
  width: 20px;
  height: 20px;
  cursor: pointer; }

.all-left {
  background-image: url(/images/all_left.png); }

.all-right {
  background-image: url(/images/all_right.png); }

.one-left {
  background-image: url(/images/one_left.png); }

.one-right {
  background-image: url(/images/one_right.png); }

.one-up {
  background-image: url(/images/one_up.png); }

.one-down {
  background-image: url(/images/one_down.png); }

input.wide {
  width: 340px; }

#sendmsg {
  width: 440px;
  font-size: 11px;
  font-family: Verdana, Tahoma, Arial, Sans-Serif;
  color: #6b6b6b; }

#sendmsg table {
  margin: 0; }

#message-layout th {
  vertical-align: top;
  text-align: right;
  padding: 13px 10px 10px 0; }

#message-layout th.middle {
  vertical-align: middle;
  padding: 0 10px 0 0; }

#message-layout td {
  padding: 10px 0; }

#message-layout #userGroupChooser td {
  padding: 0; }

#message-layout textarea {
  width: 340px;
  height: 150px;
  padding: 3px;
  font-family: Verdana, Tahoma, Arial, Sans-Serif;
  font-size: 11px; }

#message-layout input.text {
  padding: 3px; }

#sendmsg input.radio {
  float: left;
  margin-right: 5px;
  display: inline; }

#sendmsg input.submit {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 5px 2px; }

#sendmsg .footer label {
  float: left;
  display: inline;
  margin-bottom: 5px; }

#sendmsg .footer label.disabled {
  color: #ccc;
  cursor: default; }

#sendmsg .footer p {
  float: left;
  width: 170px;
  text-align: left;
  margin-left: 75px; }

#sendmsg .body {
  padding-bottom: 0; }

#sendmsg h1 {
  text-transform: none; }

#sendmsg .cancel,
#send-team-message {
  margin-right: 3px; }

#teamMenu {
  width: 340px;
  font-size: 12px; }

#teamChooser {
  width: 340px; }

#teamChooser p {
  font-size: 12px;
  color: #333;
  font-style: normal;
  float: none;
  width: 300px; }

.char-limiter {
  color: #B43333;
  font-weight: bold; }

input.filter {
  margin-bottom: 3px;
  margin-left: 0px;
  width: 150px;
  _margin-bottom: 0px;
  padding: 1px; }

td.buttons-parent {
  width: 30px;
  vertical-align: middle; }

#attach-files {
  border: dashed 2px #999;
  width: 340px;
  padding: 0; }

/* file upload css */
.mm-uploader {
  position: relative;
  width: 100%; }

.mm-upload-button {
  color: blue; }

.mm-upload-button-focus {
  outline: 1px dotted black; }

.mm-upload-drop-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 90px;
  z-index: 2;
  background: #FF9797;
  text-align: center; }

.mm-drop-area {
  padding: 10px; }

.mm-upload-drop-area span {
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -8px;
  font-size: 16px; }

.mm-drop-area {
  overflow: hidden; }

.mm-upload-drop-area-active {
  background: #FFFFFF; }

.mm-upload-list {
  list-style: none; }

.mm-upload-list li {
  margin: 0;
  padding: 8px 0 0 0;
  line-height: 15px;
  font-size: 11px; }

.mm-upload-file, .mm-upload-spinner, .mm-upload-size, .mm-upload-cancel, .mm-upload-failed-text {
  margin-right: 7px; }

.mm-upload-cancel {
  font-size: 9px !important; }

.mm-upload-spinner {
  display: inline-block;
  background: url("/images/loading.gif");
  width: 15px;
  height: 15px;
  vertical-align: text-bottom; }

.mm-upload-size, .mm-upload-cancel {
  font-size: 11px; }

.mm-upload-size {
  display: none; }

.mm-upload-failed-text {
  display: none; }

.mm-upload-fail .mm-upload-failed-text {
  display: inline; }

.mm-upload-remove {
  display: none;
  /* hide by default */
  font-size: 9px; }

#sendmsg, #sendmsg * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

#sendmsg td, #sendmsg label {
  line-height: 1;
  font-size: 11px;
  font-family: Verdana, Tahoma, Arial, Sans-Serif;
  color: #6b6b6b; }

#sendmsg tr {
  font-size: 11px; }

/* Loader */
#sendmsg .loading {
  color: #FFFFFF;
  font-weight: bold;
  background: transparent url(/images/overlay_bg80.png) repeat;
  padding: 7px;
  border-radius: 3px;
  -moz-border-radius: 3px; }

#sendmsg .loading p {
  padding-left: 20px;
  background: url(/images/dark-loading.gif) no-repeat left center;
  line-height: 18px; }

#sendmsg img {
  vertical-align: middle; }

#sendmsg.modalPopup {
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  position: absolute;
  padding: 15px;
  background: transparent url(/images/overlay_bg60.png) repeat; }

#sendmsg.modalPopup p {
  max-width: 340px; }

#sendmsg.modalPopup .mheader {
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  background-color: #FF8E33;
  padding: 5px 10px;
  border-bottom: 1px solid #FF6600;
  cursor: default; }

#sendmsg.modalPopup h1 {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: -1px;
  font-family: Verdana, Sans-Serif !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

#sendmsg.modalPopup .subtitle {
  display: block;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: normal; }

#sendmsg.modalPopup .body, #sendmsg .modalPopup .mbody {
  padding: 10px;
  background-color: #E8E8E8;
  border-top: 1px solid #f4f4f4; }

#sendmsg.modalPopup th {
  font-weight: bold; }

#sendmsg.modalPopup .nob {
  border-top: none; }

#sendmsg.modalPopup .footer {
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  padding: 8px 12px 15px 0px;
  text-align: right;
  background-color: #E8E8E8; }

#sendmsg.modalPopup .footer input {
  cursor: pointer; }

#sendmsg.modalPopup .footer input[disabled] {
  cursor: default; }

#sendmsg.modalPopup .footer textarea {
  margin-top: 8px;
  width: 350px; }

#sendmsg.modalPopup .footer span.command {
  cursor: pointer; }

#sendmsg.modalPopup a.disabled {
  color: #6B6B6B;
  text-decoration: none;
  cursor: default; }

#sendmsg.modalPopup a.disabled:hover {
  background: none;
  text-decoration: none;
  cursor: default; }

#sendmsg .dlg_close {
  font-size: 0px;
  line-height: 0px;
  text-indent: -9999px;
  height: 24px;
  width: 24px;
  position: absolute;
  top: -10px;
  right: -8px;
  background: url(/images/dlg_close.png) no-repeat center center; }

#sendmsg .dlg_close:hover {
  background-color: Transparent; }

nav.ko-nav #messaging-link {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: none; }

@media only screen and (min-width: 65em) {
  nav.ko-nav #messaging-link .ko-nav-icon {
    width: 2.25rem;
    height: 2.25rem; }
  nav.ko-nav #messaging-link svg, nav.ko-nav #messaging-link path, nav.ko-nav #messaging-link polygon {
    fill: rgba(255, 255, 255, 0.5); }
  nav.ko-nav #messaging-link:hover svg, nav.ko-nav #messaging-link:hover path, nav.ko-nav #messaging-link:hover polygon {
    fill: white; }
  span.ko-nav-icon #unread-message-icon {
    top: 12px;
    display: block;
    position: absolute;
    right: 8px;
    width: 9px;
    height: 9px;
    background: #d81d1d;
    border: 1px solid #232a31;
    box-sizing: border-box;
    border-radius: 50%; } }

/*doc
---
title: Icons
name: icons
category: Elements
---

We're using SVG icons now, and they're all included with Kickoff. The file path is `/code/content/kickoff/icons`. We have three ways to insert them as native SVG elements so they can be styled via CSS (which you'll *need* to do, especially height and width).

**Razor**: In your view files, put this at the top: `@using Hudl.Kickoff.Webapp.Web.Extensions`. Then put this anywhere you want your icon used: `@Html.Icon("svg-icon-name-here")`.

**Handlebars**: Coming soon.

**SvgReplacer**: Insert an 'img' tag with the full file path and then use the SvgReplacer to inline the SVG.

```html_example

<div class="some-icons">
    <img src="icons/logomark.svg">
    <img src="icons/add.svg">
    <img src="icons/chevronleft.svg" >
</div>

```

```
.some-icons {
    svg, path, polygon {
        width: 2rem;
        height: 2rem;
        fill: $dark2;
    }
}
```

<div class="all-the-icons"></div>

*/
.some-icons svg, .some-icons path, .some-icons polygon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  fill: #4E5D6C; }

.all-the-icons {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap; }
  .all-the-icons:after {
    content: "";
    display: table;
    clear: both; }
  .all-the-icons svg {
    width: 4rem;
    height: 4rem;
    fill: #4E5D6C;
    background-color: #E8E8E8;
    border-radius: 2px;
    padding: 1rem; }
  .all-the-icons path, .all-the-icons polygon {
    fill: #4E5D6C; }

.ko-icon-container {
  width: 33%;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }
  .ko-icon-container div:last-child {
    margin-left: 1rem; }

.ko-docs .ko-content {
  padding: 1rem;
  max-width: 50rem;
  margin: 0 auto;
  position: relative; }
  .ko-docs .ko-content > h1 {
    padding-top: 2rem;
    color: #38434F;
    position: relative; }
    .ko-docs .ko-content > h1:first-child {
      margin-top: 0;
      border-top: none; }

.codeExample {
  border-top: 1px solid #E8E8E8;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 3rem;
  position: relative; }
  .codeExample:before {
    content: 'Example:';
    position: absolute;
    color: #8A8A8A;
    font-weight: bold;
    left: 0;
    bottom: 100%;
    padding: 0 0 .25rem 0;
    text-transform: uppercase;
    font-size: 0.75rem; }

.exampleOutput {
  margin-bottom: 2rem; }

.codeBlock {
  background-color: #F5F5F5;
  border: 1px solid #E8E8E8;
  padding: 1rem;
  font-family: "Source Code Pro";
  margin-bottom: 2rem;
  font-size: 0.75rem; }
  .codeBlock pre {
    white-space: pre-wrap; }

.ko-color-block-ui_dark_color {
  width: 10rem;
  height: 10rem;
  background-color: #232A31;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_dark_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_dark_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_dark_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_dark_medium_color {
  width: 10rem;
  height: 10rem;
  background-color: #38434F;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_dark_medium_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_dark_medium_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_dark_medium_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_dark_light_color {
  width: 10rem;
  height: 10rem;
  background-color: #4E5D6C;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_dark_light_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_dark_light_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_dark_light_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_gray_color {
  width: 10rem;
  height: 10rem;
  background-color: #8A8A8A;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_gray_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_gray_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_gray_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_gray_medium_color {
  width: 10rem;
  height: 10rem;
  background-color: #E8E8E8;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_gray_medium_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_gray_medium_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_gray_medium_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_gray_light_color {
  width: 10rem;
  height: 10rem;
  background-color: #F5F5F5;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_gray_light_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_gray_light_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_gray_light_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_blue_color {
  width: 10rem;
  height: 10rem;
  background-color: #00AEEF;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_blue_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_blue_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_blue_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_orange_color {
  width: 10rem;
  height: 10rem;
  background-color: #FF6600;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_orange_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_orange_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_orange_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_red_color {
  width: 10rem;
  height: 10rem;
  background-color: #D81D1D;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_red_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_red_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_red_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_yellow_color {
  width: 10rem;
  height: 10rem;
  background-color: #FABE39;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_yellow_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_yellow_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_yellow_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_green_color {
  width: 10rem;
  height: 10rem;
  background-color: #6AA220;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_green_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_green_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_green_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_text_dark_color {
  width: 10rem;
  height: 10rem;
  background-color: #333333;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_text_dark_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_text_dark_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_text_dark_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_text_medium_color {
  width: 10rem;
  height: 10rem;
  background-color: #666666;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_text_medium_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_text_medium_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_text_medium_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_text_light_color {
  width: 10rem;
  height: 10rem;
  background-color: #999999;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_text_light_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_text_light_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_text_light_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_text_extra_light_color {
  width: 10rem;
  height: 10rem;
  background-color: #CCCCCC;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_text_extra_light_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_text_extra_light_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_text_extra_light_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_white_color {
  width: 10rem;
  height: 10rem;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_white_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_white_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_white_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-ui_black_color {
  width: 10rem;
  height: 10rem;
  background-color: #000000;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-ui_black_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-ui_black_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-ui_black_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_default_color {
  width: 10rem;
  height: 10rem;
  background-color: #232A31;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_default_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_default_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_default_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_light_gray_color {
  width: 10rem;
  height: 10rem;
  background-color: #E9E9E9;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_light_gray_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_light_gray_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_light_gray_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_yellow_color {
  width: 10rem;
  height: 10rem;
  background-color: #FFDD00;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_yellow_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_yellow_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_yellow_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_gold_color {
  width: 10rem;
  height: 10rem;
  background-color: #C5B358;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_gold_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_gold_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_gold_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_orange_color {
  width: 10rem;
  height: 10rem;
  background-color: #F87620;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_orange_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_orange_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_orange_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_light_red_color {
  width: 10rem;
  height: 10rem;
  background-color: #F30000;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_light_red_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_light_red_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_light_red_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_red_color {
  width: 10rem;
  height: 10rem;
  background-color: #C20000;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_red_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_red_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_red_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_maroon_color {
  width: 10rem;
  height: 10rem;
  background-color: #7A1700;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_maroon_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_maroon_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_maroon_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_black_color {
  width: 10rem;
  height: 10rem;
  background-color: #171919;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_black_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_black_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_black_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_light_blue_color {
  width: 10rem;
  height: 10rem;
  background-color: #68B2D8;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_light_blue_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_light_blue_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_light_blue_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_blue_color {
  width: 10rem;
  height: 10rem;
  background-color: #1652AE;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_blue_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_blue_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_blue_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_dark_blue_color {
  width: 10rem;
  height: 10rem;
  background-color: #0F2A4B;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_dark_blue_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_dark_blue_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_dark_blue_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_purple_color {
  width: 10rem;
  height: 10rem;
  background-color: #332064;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_purple_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_purple_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_purple_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_green_color {
  width: 10rem;
  height: 10rem;
  background-color: #2B7834;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_green_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_green_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_green_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-team_dark_green_color {
  width: 10rem;
  height: 10rem;
  background-color: #1F4615;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-team_dark_green_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-team_dark_green_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-team_dark_green_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_white_color {
  width: 10rem;
  height: 10rem;
  background-color: #E9E9E9;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_white_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_white_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_white_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_gray_color {
  width: 10rem;
  height: 10rem;
  background-color: #8C9196;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_gray_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_gray_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_gray_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_black_color {
  width: 10rem;
  height: 10rem;
  background-color: #404040;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_black_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_black_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_black_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_yellow_color {
  width: 10rem;
  height: 10rem;
  background-color: #FFDD30;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_yellow_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_yellow_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_yellow_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_orange_color {
  width: 10rem;
  height: 10rem;
  background-color: #ED9827;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_orange_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_orange_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_orange_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_red_color {
  width: 10rem;
  height: 10rem;
  background-color: #DF4953;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_red_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_red_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_red_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_green_color {
  width: 10rem;
  height: 10rem;
  background-color: #42BE39;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_green_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_green_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_green_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_blue_color {
  width: 10rem;
  height: 10rem;
  background-color: #3985BE;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_blue_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_blue_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_blue_color div:last-child {
    font-size: 1.125rem; }

.ko-color-block-tagging_team_purple_color {
  width: 10rem;
  height: 10rem;
  background-color: #9F62B1;
  border-radius: 50%;
  position: relative;
  color: #FFFFFF;
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .ko-color-block-tagging_team_purple_color.ko-color-light {
    color: #232A31; }
  .ko-color-block-tagging_team_purple_color div:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4; }
  .ko-color-block-tagging_team_purple_color div:last-child {
    font-size: 1.125rem; }

.ko-color-blocks {
  margin-bottom: 1.5rem; }
  .ko-color-blocks:after {
    content: "";
    display: table;
    clear: both; }

.ko-docs-side-nav {
  position: fixed;
  left: 1rem;
  top: 8rem; }
  .ko-docs-side-nav li a {
    color: #FF6600;
    font-weight: bold;
    display: block;
    padding-bottom: .5rem;
    padding-top: .5rem;
    border-bottom: 1px solid #E8E8E8;
    width: 10rem; }

hll {
  background-color: #ffffcc; }

.c {
  color: #999988;
  font-style: italic; }

/* Comment */
.err {
  color: #a61717;
  background-color: #e3d2d2; }

/* Error */
.k {
  color: #000000;
  font-weight: bold; }

/* Keyword */
.o {
  color: #000000;
  font-weight: bold; }

/* Operator */
.cm {
  color: #999988;
  font-style: italic; }

/* Comment.Multiline */
.cp {
  color: #999999;
  font-weight: bold;
  font-style: italic; }

/* Comment.Preproc */
.c1 {
  color: #999988;
  font-style: italic; }

/* Comment.Single */
.cs {
  color: #999999;
  font-weight: bold;
  font-style: italic; }

/* Comment.Special */
.gd {
  color: #000000;
  background-color: #ffdddd; }

/* Generic.Deleted */
.ge {
  color: #000000;
  font-style: italic; }

/* Generic.Emph */
.gr {
  color: #aa0000; }

/* Generic.Error */
.gh {
  color: #999999; }

/* Generic.Heading */
.gi {
  color: #000000;
  background-color: #ddffdd; }

/* Generic.Inserted */
.go {
  color: #888888; }

/* Generic.Output */
.gp {
  color: #555555; }

/* Generic.Prompt */
.gs {
  font-weight: bold; }

/* Generic.Strong */
.gu {
  color: #aaaaaa; }

/* Generic.Subheading */
.gt {
  color: #aa0000; }

/* Generic.Traceback */
.kc {
  color: #000000;
  font-weight: bold; }

/* Keyword.Constant */
.kd {
  color: #000000;
  font-weight: bold; }

/* Keyword.Declaration */
.kn {
  color: #000000;
  font-weight: bold; }

/* Keyword.Namespace */
.kp {
  color: #000000;
  font-weight: bold; }

/* Keyword.Pseudo */
.kr {
  color: #000000;
  font-weight: bold; }

/* Keyword.Reserved */
.kt {
  color: #445588;
  font-weight: bold; }

/* Keyword.Type */
.m {
  color: #009999; }

/* Literal.Number */
.s {
  color: #d01040; }

/* Literal.String */
.na {
  color: #008080; }

/* Name.Attribute */
.nb {
  color: #0086B3; }

/* Name.Builtin */
.nc {
  color: #445588;
  font-weight: bold; }

/* Name.Class */
.no {
  color: #008080; }

/* Name.Constant */
.nd {
  color: #3c5d5d;
  font-weight: bold; }

/* Name.Decorator */
.ni {
  color: #800080; }

/* Name.Entity */
.ne {
  color: #990000;
  font-weight: bold; }

/* Name.Exception */
.nf {
  color: #990000;
  font-weight: bold; }

/* Name.Function */
.nl {
  color: #990000;
  font-weight: bold; }

/* Name.Label */
.nn {
  color: #555555; }

/* Name.Namespace */
.nt {
  color: #000080; }

/* Name.Tag */
.nv {
  color: #008080; }

/* Name.Variable */
.ow {
  color: #000000;
  font-weight: bold; }

/* Operator.Word */
.w {
  color: #bbbbbb; }

/* Text.Whitespace */
.mf {
  color: #009999; }

/* Literal.Number.Float */
.mh {
  color: #009999; }

/* Literal.Number.Hex */
.mi {
  color: #009999; }

/* Literal.Number.Integer */
.mo {
  color: #009999; }

/* Literal.Number.Oct */
.sb {
  color: #d01040; }

/* Literal.String.Backtick */
.sc {
  color: #d01040; }

/* Literal.String.Char */
.sd {
  color: #d01040; }

/* Literal.String.Doc */
.s2 {
  color: #d01040; }

/* Literal.String.Double */
.se {
  color: #d01040; }

/* Literal.String.Escape */
.sh {
  color: #d01040; }

/* Literal.String.Heredoc */
.si {
  color: #d01040; }

/* Literal.String.Interpol */
.sx {
  color: #d01040; }

/* Literal.String.Other */
.sr {
  color: #009926; }

/* Literal.String.Regex */
.s1 {
  color: #d01040; }

/* Literal.String.Single */
.ss {
  color: #990073; }

/* Literal.String.Symbol */
.bp {
  color: #999999; }

/* Name.Builtin.Pseudo */
.vc {
  color: #008080; }

/* Name.Variable.Class */
.vg {
  color: #008080; }

/* Name.Variable.Global */
.vi {
  color: #008080; }

/* Name.Variable.Instance */
.il {
  color: #009999; }

/* Literal.Number.Integer.Long */
.habla_window_div_position {
  bottom: 10px;
  position: fixed;
  right: 10px; }

.habla_window_div_position_floating {
  bottom: 10px;
  position: fixed;
  right: 10px; }

.habla_window_div_position_floating_ie {
  bottom: 10px;
  position: absolute;
  right: 10px; }

#habla_expanded_div {
  background: url("/images/overlay_grad_03.gif") repeat-x scroll 0 0 #202020; }

#habla_topbar_div {
  position: relative;
  -moz-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.habla_window_div_base {
  border: 0 none !important;
  font-family: arial, helvetica, sans-serif;
  font-style: inherit;
  font-weight: inherit;
  line-height: 1;
  margin: 0 !important;
  outline-color: -moz-use-text-color;
  outline-style: none !important;
  outline-width: 0;
  padding: 0 !important;
  text-align: left;
  vertical-align: baseline;
  z-index: 99999 !important;
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5); }

.habla_panel_border {
  background: #FFFFFF none repeat scroll 0 0;
  border: 0;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  width: 250px; }

a.habla_button_a_hover:hover {
  color: #fff;
  font-size: 14px;
  background: none;
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer; }

.habla_button_a_normal {
  color: #fff;
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer; }

.habla_button {
  float: right;
  font-weight: bold;
  margin-left: 3px;
  padding: 0 6px 2px;
  text-decoration: none !important; }

.habla_conversation_message_off {
  margin: 0 !important;
  padding: 3px 3px 3px 23px; }

.habla_conversation_message_on {
  margin: 0;
  padding: 5px; }

.habla_conversation_p_item {
  background: transparent;
  color: #000;
  margin: 0;
  padding: 0;
  text-indent: -20px; }

.habla_conversation_person1 {
  color: #6b6b6b;
  padding-right: 5px;
  font-weight: bold;
  font-size: 12px; }

.habla_conversation_person2 {
  color: #ff8e33;
  padding-right: 5px;
  font-weight: bold;
  font-size: 12px; }

.habla_conversation_text_span {
  color: #aaa;
  font-size: 12px; }

.habla_topbar_div_highlighted {
  background: red;
  color: #0FF;
  padding: 6px;
  border: 1px solid red; }

.habla_topbar_div_normal {
  background: #f60 url(/images/gradsprite.gif) repeat-x left top;
  color: #fff;
  padding: 6px;
  border: 1px solid #f60; }

.habla_topbar_clickable {
  cursor: pointer; }

.habla_oplink_a_hover {
  color: #fff;
  background: none;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

a.habla_oplink_a_hover:hover {
  font-size: 14px;
  background: none; }

.habla_oplink_a_normal {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

.clear_style {
  clear: both; }

.habla_offline_message_div {
  border-bottom: 1px dotted #CCCCCC;
  line-height: 1.5em;
  margin: 0;
  padding: 3px; }

.habla_conversation_div {
  background: transparent;
  border-bottom: 1px dotted #555;
  height: 155px;
  line-height: 1.25em;
  overflow: auto;
  color: #aaa;
  padding: 3px 3px 3px 23px !important; }

.habla_pre_chat_div {
  background: transparent none repeat scroll 0 0;
  border-bottom: 1px dotted #CCCCCC;
  height: 155px;
  line-height: 1.25em;
  overflow: auto;
  color: #000000;
  padding: 3px 3px 3px 3px; }

.habla_offline_message_sent_div {
  background: transparent none repeat scroll 0 0;
  border-bottom: 1px dotted #CCCCCC;
  height: 155px;
  line-height: 1.5em;
  overflow: auto !important;
  padding: 3px !important; }

.habla_chatform_form {
  margin: 0 !important;
  padding: 0 !important; }

.habla_input_div {
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal !important;
  margin: 0 !important;
  padding: 3px !important; }

.habla_offline_body_input {
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  height: 155px;
  vertical-align: text-top;
  width: 234px; }

.habla_say_text_span {
  color: #000000; }

.habla_submit_button {
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  margin: 0 !important;
  vertical-align: text-top;
  width: 234px; }

.habla_offline_submit_input {
  background: #E75917 none repeat scroll 0 0 !important;
  border: medium none #CCCCCC !important;
  color: white !important;
  float: right;
  margin-right: 2px; }

.habla_offline_error_span {
  float: left;
  font-family: arial, helvetica, sans-serif;
  font-size: 12px; }

.habla_wcsend_input_normal {
  border: 2px solid #6b6b6b !important; }

.habla_wcsend_input_pre {
  color: #6b6b6b; }

.habla_wcsend_input_post {
  color: #000000; }

.habla_wcsend_input_pre_highlighted {
  border: 2px solid #6b6b6b !important; }

.habla_wcsend_input_highlighted {
  border: 2px solid !important;
  color: #000000 !important; }

.habla_wcsend_field {
  background: transparent none repeat scroll 0 0;
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  height: 24px;
  margin: 0 !important;
  overflow: auto !important;
  padding: 1px 3px !important;
  vertical-align: text-top;
  width: 234px; }

.habla_wcsend_field:hover {
  background: #FFFF9F;
  border-color: #09f !important;
  outline-color: #09f !important;
  color: #333; }

.habla_wcsend_field:focus {
  background: #fff;
  border-color: #f60 !important;
  outline-color: #f60 !important;
  color: #000 !important; }

#habla_link_div a:hover {
  background: none; }

.hbl_pal_remote_fg + .habla_conversation_text_span {
  color: #fff; }

.olark-composing-message {
  font-style: italic;
  color: #6b6b6b !important; }

/* Account alert banner */
#expiration-countdown {
  margin: 0 auto 0 auto;
  width: 1100px; }
  #expiration-countdown.banner_notice {
    padding: 15px 15px 15px 25px;
    background: #f8f8f8 url(/images/announce-bg.png) repeat-x left top;
    border: 1px solid #d8d8d8;
    border-bottom: 1px solid #b4b4b4; }
    #expiration-countdown.banner_notice .fl-l {
      float: left;
      width: 400px; }
      #expiration-countdown.banner_notice .fl-l h3 {
        font-family: inherit;
        font-size: 14px; }
      #expiration-countdown.banner_notice .fl-l > span {
        display: block;
        margin-top: 3px;
        line-height: 1; }
    #expiration-countdown.banner_notice.clearfix:before, #expiration-countdown.banner_notice.clearfix:after {
      content: "";
      display: table; }
    #expiration-countdown.banner_notice.clearfix:after {
      clear: both;
      content: '.';
      display: block;
      visibility: hidden;
      height: 0; }
    #expiration-countdown.banner_notice.clearfix {
      display: block;
      zoom: 1; }
    #expiration-countdown.banner_notice img {
      display: inline;
      padding: 5px;
      background: #FFF;
      border: 1px solid #b4b4b4;
      float: right;
      margin-left: 10px; }
    #expiration-countdown.banner_notice strong {
      color: #333; }
    #expiration-countdown.banner_notice p {
      color: #6b6b6b;
      margin: 2px 0;
      padding: 0;
      font-size: 12px; }
    #expiration-countdown.banner_notice p.first {
      margin: 5px 0 10px 0; }
    #expiration-countdown.banner_notice h2 {
      color: #F60; }
  #expiration-countdown .billcta,
  #expiration-countdown .billcta:visited,
  #expiration-countdown .billcta:link {
    display: inline-block;
    padding: 3px 4px;
    border: 1px solid #06f;
    color: #fff;
    background: #09f url(/images/catsel.gif) repeat-x left top;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.76);
    -moz-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.76);
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6); }
  #expiration-countdown .billcta:hover {
    background-position: left bottom; }
  #expiration-countdown #hide-banner-btn {
    display: none;
    text-align: right; }
  #expiration-countdown .progress_meter {
    float: right;
    width: 470px;
    margin: 0 0 0 15px; }
    #expiration-countdown .progress_meter .bar_w {
      background: #d8d8d8;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      margin: 0 0 3px 0; }
    #expiration-countdown .progress_meter .bar {
      background: #ec1427 url(/images/meter-bgs.png) repeat-x 0 -48px;
      border: 1px solid #ec1427;
      text-align: right;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px; }
      #expiration-countdown .progress_meter .bar span {
        font-weight: bold;
        font-size: 10px;
        color: #FFF;
        padding: 2px 8px;
        display: inline-block; }
    #expiration-countdown .progress_meter .text {
      text-align: right;
      color: #ec1427;
      font-weight: bold; }
    #expiration-countdown .progress_meter span {
      color: #6b6b6b;
      font-weight: normal; }
    #expiration-countdown .progress_meter .icon {
      width: 16px;
      height: 16px;
      background: transparent no-repeat center center scroll;
      margin-right: 6px;
      vertical-align: bottom; }
    #expiration-countdown .progress_meter.low .icon {
      background-image: url(/images/warn16.png); }

/*
Hack to fix x overflow on mobile caused by the sidenav.
This should not be considered a permanent solution since the menu disappears
before the closing animation completes, which is why this change wasn't
committed into Kickoff itself.
*/
.ko-nav {
  display: none; }
  @media only screen and (min-width: 65em) {
    .ko-nav {
      display: flex; } }
  .desktop-only .ko-nav {
    display: flex; }
  html.ko-sidemenu-left-is-open .ko-nav {
    display: block; }

/*
We have to recreate our kickoff buttons to avoid conflicts with feed-consumer
which has _home-buttons.scss included, which modifies the button styles. This
should be fixed in feed-consumer at some point so this isn't necessary when
importing the timeline.
*/
.ko-subheader {
  justify-content: flex-start; }

.subnav-placeholder {
  margin-bottom: 20px; }

.hudl-subnav {
  float: left;
  width: 100%; }
  .hudl-subnav .hudl-subnav-inner {
    margin: 0 auto;
    width: 100%; }
    .hudl-subnav .hudl-subnav-inner .desktop-only {
      display: inline; }
      @media (min-width: 5.25rem) {
        .hudl-subnav .hudl-subnav-inner .desktop-only {
          display: none; } }
      @media (min-width: 37.5rem) {
        .hudl-subnav .hudl-subnav-inner .desktop-only {
          display: none; } }
      @media (min-width: 50rem) {
        .hudl-subnav .hudl-subnav-inner .desktop-only {
          display: inline; } }
  .hudl-subnav .hudl-subnav-team {
    color: #e4e7eb; }
    .hudl-subnav .hudl-subnav-team:hover {
      color: #ff6300;
      border-bottom: 0; }
  .hudl-subnav .hudl-subnav-edit-team-profile {
    float: right; }
    @media (min-width: 55) {
      .hudl-subnav .hudl-subnav-edit-team-profile {
        display: none; } }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
@-webkit-keyframes coloredCircleGrow {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(26); } }

@-moz-keyframes coloredCircleGrow {
  0% {
    -moz-transform: scale(0); }
  100% {
    -moz-transform: scale(26); } }

@keyframes coloredCircleGrow {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(26);
    -moz-transform: scale(26);
    -ms-transform: scale(26);
    -o-transform: scale(26);
    transform: scale(26); } }

@-webkit-keyframes whiteCircleGrow {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(28); } }

@-moz-keyframes whiteCircleGrow {
  0% {
    -moz-transform: scale(0); }
  100% {
    -moz-transform: scale(28); } }

@keyframes whiteCircleGrow {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(28);
    -moz-transform: scale(28);
    -ms-transform: scale(28);
    -o-transform: scale(28);
    transform: scale(28); } }

@-webkit-keyframes lightningGrow {
  0% {
    -webkit-transform: translate(7px, 0) scale(0); }
  50% {
    -webkit-transform: translate(7px, 0) scale(1.5); }
  80% {
    -webkit-transform: translate(7px, 0) scale(0.9); }
  100% {
    -webkit-transform: translate(7px, 0) scale(1); } }

@-moz-keyframes lightningGrow {
  0% {
    -moz-transform: translate(7px, 0) scale(0); }
  50% {
    -moz-transform: translate(7px, 0) scale(1.5); }
  80% {
    -moz-transform: translate(7px, 0) scale(0.9); }
  100% {
    -moz-transform: translate(7px, 0) scale(1); } }

@keyframes lightningGrow {
  0% {
    -webkit-transform: translate(7px, 0) scale(0);
    -moz-transform: translate(7px, 0) scale(0);
    -ms-transform: translate(7px, 0) scale(0);
    -o-transform: translate(7px, 0) scale(0);
    transform: translate(7px, 0) scale(0); }
  50% {
    -webkit-transform: translate(7px, 0) scale(1.5);
    -moz-transform: translate(7px, 0) scale(1.5);
    -ms-transform: translate(7px, 0) scale(1.5);
    -o-transform: translate(7px, 0) scale(1.5);
    transform: translate(7px, 0) scale(1.5); }
  80% {
    -webkit-transform: translate(7px, 0) scale(0.9);
    -moz-transform: translate(7px, 0) scale(0.9);
    -ms-transform: translate(7px, 0) scale(0.9);
    -o-transform: translate(7px, 0) scale(0.9);
    transform: translate(7px, 0) scale(0.9); }
  100% {
    -webkit-transform: translate(7px, 0) scale(1);
    -moz-transform: translate(7px, 0) scale(1);
    -ms-transform: translate(7px, 0) scale(1);
    -o-transform: translate(7px, 0) scale(1);
    transform: translate(7px, 0) scale(1); } }

@-webkit-keyframes lightningFlash {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes lightningFlash {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes lightningFlash {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes heartBodyGrow {
  0% {
    -webkit-transform: translate(3px, 10px) scale(0); }
  50% {
    -webkit-transform: translate(3px, 10px) scale(1.5); }
  90% {
    -webkit-transform: translate(3px, 10px) scale(0.9); }
  100% {
    -webkit-transform: translate(3px, 10px) scale(1); } }

@-moz-keyframes heartBodyGrow {
  0% {
    -moz-transform: translate(3px, 10px) scale(0); }
  50% {
    -moz-transform: translate(3px, 10px) scale(1.5); }
  90% {
    -moz-transform: translate(3px, 10px) scale(0.9); }
  100% {
    -moz-transform: translate(3px, 10px) scale(1); } }

@keyframes heartBodyGrow {
  0% {
    -webkit-transform: translate(3px, 10px) scale(0);
    -moz-transform: translate(3px, 10px) scale(0);
    -ms-transform: translate(3px, 10px) scale(0);
    -o-transform: translate(3px, 10px) scale(0);
    transform: translate(3px, 10px) scale(0); }
  50% {
    -webkit-transform: translate(3px, 10px) scale(1.5);
    -moz-transform: translate(3px, 10px) scale(1.5);
    -ms-transform: translate(3px, 10px) scale(1.5);
    -o-transform: translate(3px, 10px) scale(1.5);
    transform: translate(3px, 10px) scale(1.5); }
  90% {
    -webkit-transform: translate(3px, 10px) scale(0.9);
    -moz-transform: translate(3px, 10px) scale(0.9);
    -ms-transform: translate(3px, 10px) scale(0.9);
    -o-transform: translate(3px, 10px) scale(0.9);
    transform: translate(3px, 10px) scale(0.9); }
  100% {
    -webkit-transform: translate(3px, 10px) scale(1);
    -moz-transform: translate(3px, 10px) scale(1);
    -ms-transform: translate(3px, 10px) scale(1);
    -o-transform: translate(3px, 10px) scale(1);
    transform: translate(3px, 10px) scale(1); } }

@-webkit-keyframes heartRaysGrow {
  0% {
    -webkit-transform: translate(0px, 5px) scale(0); }
  50% {
    -webkit-transform: translate(0px, 3px) scale(0); }
  75% {
    -webkit-transform: translate(0px, 1px) scale(1.5); }
  95% {
    -webkit-transform: translate(0px, 0px) scale(0.9); }
  100% {
    -webkit-transform: translate(0px, 0px) scale(1); } }

@-moz-keyframes heartRaysGrow {
  0% {
    -moz-transform: translate(0px, 5px) scale(0); }
  50% {
    -moz-transform: translate(0px, 3px) scale(0); }
  75% {
    -moz-transform: translate(0px, 1px) scale(1.5); }
  95% {
    -moz-transform: translate(0px, 0px) scale(0.9); }
  100% {
    -moz-transform: translate(0px, 0px) scale(1); } }

@keyframes heartRaysGrow {
  0% {
    -webkit-transform: translate(0px, 5px) scale(0);
    -moz-transform: translate(0px, 5px) scale(0);
    -ms-transform: translate(0px, 5px) scale(0);
    -o-transform: translate(0px, 5px) scale(0);
    transform: translate(0px, 5px) scale(0); }
  50% {
    -webkit-transform: translate(0px, 3px) scale(0);
    -moz-transform: translate(0px, 3px) scale(0);
    -ms-transform: translate(0px, 3px) scale(0);
    -o-transform: translate(0px, 3px) scale(0);
    transform: translate(0px, 3px) scale(0); }
  75% {
    -webkit-transform: translate(0px, 1px) scale(1.5);
    -moz-transform: translate(0px, 1px) scale(1.5);
    -ms-transform: translate(0px, 1px) scale(1.5);
    -o-transform: translate(0px, 1px) scale(1.5);
    transform: translate(0px, 1px) scale(1.5); }
  95% {
    -webkit-transform: translate(0px, 0px) scale(0.9);
    -moz-transform: translate(0px, 0px) scale(0.9);
    -ms-transform: translate(0px, 0px) scale(0.9);
    -o-transform: translate(0px, 0px) scale(0.9);
    transform: translate(0px, 0px) scale(0.9); }
  100% {
    -webkit-transform: translate(0px, 0px) scale(1);
    -moz-transform: translate(0px, 0px) scale(1);
    -ms-transform: translate(0px, 0px) scale(1);
    -o-transform: translate(0px, 0px) scale(1);
    transform: translate(0px, 0px) scale(1); } }

@-webkit-keyframes fireGrow {
  0% {
    -webkit-transform: translate(2px, 0px) scale(0); }
  50% {
    -webkit-transform: translate(2px, 0px) scale(1.5); }
  80% {
    -webkit-transform: translate(2px, 0px) scale(0.9); }
  100% {
    -webkit-transform: translate(2px, 0px) scale(1); } }

@-moz-keyframes fireGrow {
  0% {
    -moz-transform: translate(2px, 0px) scale(0); }
  50% {
    -moz-transform: translate(2px, 0px) scale(1.5); }
  80% {
    -moz-transform: translate(2px, 0px) scale(0.9); }
  100% {
    -moz-transform: translate(2px, 0px) scale(1); } }

@keyframes fireGrow {
  0% {
    -webkit-transform: translate(2px, 0px) scale(0);
    -moz-transform: translate(2px, 0px) scale(0);
    -ms-transform: translate(2px, 0px) scale(0);
    -o-transform: translate(2px, 0px) scale(0);
    transform: translate(2px, 0px) scale(0); }
  50% {
    -webkit-transform: translate(2px, 0px) scale(1.5);
    -moz-transform: translate(2px, 0px) scale(1.5);
    -ms-transform: translate(2px, 0px) scale(1.5);
    -o-transform: translate(2px, 0px) scale(1.5);
    transform: translate(2px, 0px) scale(1.5); }
  80% {
    -webkit-transform: translate(2px, 0px) scale(0.9);
    -moz-transform: translate(2px, 0px) scale(0.9);
    -ms-transform: translate(2px, 0px) scale(0.9);
    -o-transform: translate(2px, 0px) scale(0.9);
    transform: translate(2px, 0px) scale(0.9); }
  100% {
    -webkit-transform: translate(2px, 0px) scale(1);
    -moz-transform: translate(2px, 0px) scale(1);
    -ms-transform: translate(2px, 0px) scale(1);
    -o-transform: translate(2px, 0px) scale(1);
    transform: translate(2px, 0px) scale(1); } }

/* Ember right animation */
@-webkit-keyframes emberRightRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(220%, 20%) scale(0.6); }
  100% {
    opacity: 0;
    -webkit-transform: translate(220%, -90%) scale(0.6); } }

@-moz-keyframes emberRightRise {
  0% {
    opacity: 1;
    -moz-transform: translate(220%, 20%) scale(0.6); }
  100% {
    opacity: 0;
    -moz-transform: translate(220%, -90%) scale(0.6); } }

@keyframes emberRightRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(220%, 20%) scale(0.6);
    -moz-transform: translate(220%, 20%) scale(0.6);
    -ms-transform: translate(220%, 20%) scale(0.6);
    -o-transform: translate(220%, 20%) scale(0.6);
    transform: translate(220%, 20%) scale(0.6); }
  100% {
    opacity: 0;
    -webkit-transform: translate(220%, -90%) scale(0.6);
    -moz-transform: translate(220%, -90%) scale(0.6);
    -ms-transform: translate(220%, -90%) scale(0.6);
    -o-transform: translate(220%, -90%) scale(0.6);
    transform: translate(220%, -90%) scale(0.6); } }

@-moz-keyframes emberRightRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(20px, 7px) scale(0.6);
    -moz-transform: translate(20px, 7px) scale(0.6);
    -ms-transform: translate(20px, 7px) scale(0.6);
    -o-transform: translate(20px, 7px) scale(0.6);
    transform: translate(20px, 7px) scale(0.6); }
  100% {
    opacity: 0;
    -webkit-transform: translate(20px, -15px) scale(0.6);
    -moz-transform: translate(20px, -15px) scale(0.6);
    -ms-transform: translate(20px, -15px) scale(0.6);
    -o-transform: translate(20px, -15px) scale(0.6);
    transform: translate(20px, -15px) scale(0.6); } }

/* Ember middle animation */
@-webkit-keyframes emberMiddleRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(85%, 20%) scale(0.8); }
  100% {
    opacity: 0;
    -webkit-transform: translate(85%, -80%) scale(0.8); } }

@-moz-keyframes emberMiddleRise {
  0% {
    opacity: 1;
    -moz-transform: translate(85%, 20%) scale(0.8); }
  100% {
    opacity: 0;
    -moz-transform: translate(85%, -80%) scale(0.8); } }

@keyframes emberMiddleRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(85%, 20%) scale(0.8);
    -moz-transform: translate(85%, 20%) scale(0.8);
    -ms-transform: translate(85%, 20%) scale(0.8);
    -o-transform: translate(85%, 20%) scale(0.8);
    transform: translate(85%, 20%) scale(0.8); }
  100% {
    opacity: 0;
    -webkit-transform: translate(85%, -80%) scale(0.8);
    -moz-transform: translate(85%, -80%) scale(0.8);
    -ms-transform: translate(85%, -80%) scale(0.8);
    -o-transform: translate(85%, -80%) scale(0.8);
    transform: translate(85%, -80%) scale(0.8); } }

@-moz-keyframes emberMiddleRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(10px, 3px) scale(0.8);
    -moz-transform: translate(10px, 3px) scale(0.8);
    -ms-transform: translate(10px, 3px) scale(0.8);
    -o-transform: translate(10px, 3px) scale(0.8);
    transform: translate(10px, 3px) scale(0.8); }
  100% {
    opacity: 0;
    -webkit-transform: translate(10px, -20px) scale(0.8);
    -moz-transform: translate(10px, -20px) scale(0.8);
    -ms-transform: translate(10px, -20px) scale(0.8);
    -o-transform: translate(10px, -20px) scale(0.8);
    transform: translate(10px, -20px) scale(0.8); } }

/* Ember left animation */
@-webkit-keyframes emberLeftRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(5%, 120%) scale(0.8); }
  100% {
    opacity: 0;
    -webkit-transform: translate(5%, -60%) scale(0.8); } }

@-moz-keyframes emberLeftRise {
  0% {
    opacity: 1;
    -moz-transform: translate(5%, 120%) scale(0.8); }
  100% {
    opacity: 0;
    -moz-transform: translate(5%, -60%) scale(0.8); } }

@keyframes emberLeftRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(5%, 120%) scale(0.8);
    -moz-transform: translate(5%, 120%) scale(0.8);
    -ms-transform: translate(5%, 120%) scale(0.8);
    -o-transform: translate(5%, 120%) scale(0.8);
    transform: translate(5%, 120%) scale(0.8); }
  100% {
    opacity: 0;
    -webkit-transform: translate(5%, -60%) scale(0.8);
    -moz-transform: translate(5%, -60%) scale(0.8);
    -ms-transform: translate(5%, -60%) scale(0.8);
    -o-transform: translate(5%, -60%) scale(0.8);
    transform: translate(5%, -60%) scale(0.8); } }

@-moz-keyframes emberLeftRise {
  0% {
    opacity: 1;
    -webkit-transform: translate(0px, 2px) scale(0.8);
    -moz-transform: translate(0px, 2px) scale(0.8);
    -ms-transform: translate(0px, 2px) scale(0.8);
    -o-transform: translate(0px, 2px) scale(0.8);
    transform: translate(0px, 2px) scale(0.8); }
  100% {
    opacity: 0;
    -webkit-transform: translate(0px, -15px) scale(0.8);
    -moz-transform: translate(0px, -15px) scale(0.8);
    -ms-transform: translate(0px, -15px) scale(0.8);
    -o-transform: translate(0px, -15px) scale(0.8);
    transform: translate(0px, -15px) scale(0.8); } }

@-webkit-keyframes hundredGrow {
  0% {
    -webkit-transform: translate(3px, 0px) scale(0); }
  50% {
    -webkit-transform: translate(3px, 0px) scale(1.5); }
  80% {
    -webkit-transform: translate(3px, 0px) scale(0.9); }
  100% {
    -webkit-transform: translate(3px, 0px) scale(1); } }

@-moz-keyframes hundredGrow {
  0% {
    -moz-transform: translate(3px, 0px) scale(0); }
  50% {
    -moz-transform: translate(3px, 0px) scale(1.5); }
  80% {
    -moz-transform: translate(3px, 0px) scale(0.9); }
  100% {
    -moz-transform: translate(3px, 0px) scale(1); } }

@keyframes hundredGrow {
  0% {
    -webkit-transform: translate(3px, 0px) scale(0);
    -moz-transform: translate(3px, 0px) scale(0);
    -ms-transform: translate(3px, 0px) scale(0);
    -o-transform: translate(3px, 0px) scale(0);
    transform: translate(3px, 0px) scale(0); }
  50% {
    -webkit-transform: translate(3px, 0px) scale(1.5);
    -moz-transform: translate(3px, 0px) scale(1.5);
    -ms-transform: translate(3px, 0px) scale(1.5);
    -o-transform: translate(3px, 0px) scale(1.5);
    transform: translate(3px, 0px) scale(1.5); }
  80% {
    -webkit-transform: translate(3px, 0px) scale(0.9);
    -moz-transform: translate(3px, 0px) scale(0.9);
    -ms-transform: translate(3px, 0px) scale(0.9);
    -o-transform: translate(3px, 0px) scale(0.9);
    transform: translate(3px, 0px) scale(0.9); }
  100% {
    -webkit-transform: translate(3px, 0px) scale(1);
    -moz-transform: translate(3px, 0px) scale(1);
    -ms-transform: translate(3px, 0px) scale(1);
    -o-transform: translate(3px, 0px) scale(1);
    transform: translate(3px, 0px) scale(1); } }

@-webkit-keyframes hundredUnderSecondLineGrow {
  0% {
    opacity: 1;
    -webkit-transform: scale(0.9); }
  80% {
    opacity: 1;
    -webkit-transform: scale(1); }
  100% {
    opacity: 1;
    -webkit-transform: scale(0); } }

@-moz-keyframes hundredUnderSecondLineGrow {
  0% {
    opacity: 1;
    -moz-transform: scale(0.9); }
  80% {
    opacity: 1;
    -moz-transform: scale(1); }
  100% {
    opacity: 1;
    -moz-transform: scale(0); } }

@keyframes hundredUnderSecondLineGrow {
  0% {
    opacity: 1;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  80% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 1;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); } }

@-webkit-keyframes hundredUnderFirstLineGrow {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.01); }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.01); }
  100% {
    opacity: 1;
    -webkit-transform: scale(0); } }

@-moz-keyframes hundredUnderFirstLineGrow {
  0% {
    opacity: 1;
    -moz-transform: scale(1.01); }
  80% {
    opacity: 1;
    -moz-transform: scale(1.01); }
  100% {
    opacity: 1;
    -moz-transform: scale(0); } }

@keyframes hundredUnderFirstLineGrow {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01); }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
    transform: scale(1.01); }
  100% {
    opacity: 1;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); } }

@-webkit-keyframes top5Grow {
  0% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5); }
  80% {
    -webkit-transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes top5Grow {
  0% {
    -moz-transform: scale(0); }
  50% {
    -moz-transform: scale(1.5); }
  80% {
    -moz-transform: scale(0.9); }
  100% {
    -moz-transform: scale(1); } }

@keyframes top5Grow {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  80% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes fiveShowAndGrow {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 0px) scale(0); }
  80% {
    opacity: 0;
    -webkit-transform: translate(0px, 0px) scale(0); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px) scale(1); } }

@-moz-keyframes fiveShowAndGrow {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, 0px) scale(0); }
  80% {
    opacity: 0;
    -moz-transform: translate(0px, 0px) scale(0); }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px) scale(1); } }

@keyframes fiveShowAndGrow {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, 0px) scale(0);
    -moz-transform: translate(0px, 0px) scale(0);
    -ms-transform: translate(0px, 0px) scale(0);
    -o-transform: translate(0px, 0px) scale(0);
    transform: translate(0px, 0px) scale(0); }
  80% {
    opacity: 0;
    -webkit-transform: translate(0px, 0px) scale(0);
    -moz-transform: translate(0px, 0px) scale(0);
    -ms-transform: translate(0px, 0px) scale(0);
    -o-transform: translate(0px, 0px) scale(0);
    transform: translate(0px, 0px) scale(0); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px) scale(1);
    -moz-transform: translate(0px, 0px) scale(1);
    -ms-transform: translate(0px, 0px) scale(1);
    -o-transform: translate(0px, 0px) scale(1);
    transform: translate(0px, 0px) scale(1); } }

@-webkit-keyframes topSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -10px); }
  80% {
    opacity: 0;
    -webkit-transform: translate(0px, -10px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px); } }

@-moz-keyframes topSlideIn {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, -10px); }
  80% {
    opacity: 0;
    -moz-transform: translate(0px, -10px); }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px); } }

@keyframes topSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -10px);
    -moz-transform: translate(0px, -10px);
    -ms-transform: translate(0px, -10px);
    -o-transform: translate(0px, -10px);
    transform: translate(0px, -10px); }
  80% {
    opacity: 0;
    -webkit-transform: translate(0px, -10px);
    -moz-transform: translate(0px, -10px);
    -ms-transform: translate(0px, -10px);
    -o-transform: translate(0px, -10px);
    transform: translate(0px, -10px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    transform: translate(0px, 0px); } }

@-webkit-keyframes transitionToBlueBorder {
  0% {
    border-color: #f9fafb; }
  100% {
    border-color: rgba(0, 156, 227, 0.3); } }

@-moz-keyframes transitionToBlueBorder {
  0% {
    border-color: #f9fafb; }
  100% {
    border-color: rgba(0, 156, 227, 0.3); } }

@keyframes transitionToBlueBorder {
  0% {
    border-color: #f9fafb; }
  100% {
    border-color: rgba(0, 156, 227, 0.3); } }

@-webkit-keyframes fadeToBlueText {
  0% {
    color: rgba(19, 41, 63, 0.65); }
  100% {
    color: rgba(0, 156, 227, 0.7); } }

@-moz-keyframes fadeToBlueText {
  0% {
    color: rgba(19, 41, 63, 0.65); }
  100% {
    color: rgba(0, 156, 227, 0.7); } }

@keyframes fadeToBlueText {
  0% {
    color: rgba(19, 41, 63, 0.65); }
  100% {
    color: rgba(0, 156, 227, 0.7); } }

/*
/ This animation should ONLY be used for IE/Edge.
/ Until they properly support scaling/translating of svg elements, we need a simplified animation
*/
@-webkit-keyframes growSvg {
  0% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5); }
  80% {
    -webkit-transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes growSvg {
  0% {
    -moz-transform: scale(0); }
  50% {
    -moz-transform: scale(1.5); }
  80% {
    -moz-transform: scale(0.9); }
  100% {
    -moz-transform: scale(1); } }

@keyframes growSvg {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  80% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes fadeOutReaction {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOutReaction {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOutReaction {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes slideRightEffect {
  0% {
    margin-left: -50px; }
  100% {
    margin-left: 1px; } }

@-moz-keyframes slideRightEffect {
  0% {
    margin-left: -50px; }
  100% {
    margin-left: 1px; } }

@keyframes slideRightEffect {
  0% {
    margin-left: -50px; }
  100% {
    margin-left: 1px; } }

.animate {
  -webkit-animation: transitionToBlueBorder 0.25s linear 0.75s;
  -moz-animation: transitionToBlueBorder 0.25s linear 0.75s;
  animation: transitionToBlueBorder 0.25s linear 0.75s;
  color: rgba(19, 41, 63, 0.65); }
  .animate .reaction-count > span {
    color: rgba(19, 41, 63, 0.65);
    font-weight: bold;
    -webkit-animation: fadeToBlueText 0.25s linear 0.75s;
    -moz-animation: fadeToBlueText 0.25s linear 0.75s;
    animation: fadeToBlueText 0.25s linear 0.75s; }
  .animate svg {
    overflow: visible;
    -webkit-animation: growSvg 0.4s ease-in-out;
    -moz-animation: growSvg 0.4s ease-in-out;
    animation: growSvg 0.4s ease-in-out; }
    @supports (transform: scale(0)) {
      .animate svg {
        -webkit-animation: none;
        -moz-animation: none;
        animation: none;
        -webkit-animation-name: none;
        -moz-animation-name: none;
        animation-name: none; }
        .animate svg .lightning > *, .animate svg .heart > *, .animate svg .top5 > *, .animate svg .hundred > *, .animate svg .fire > * {
          -webkit-transform-origin: 14px 14px;
          -moz-transform-origin: 14px 14px;
          -ms-transform-origin: 14px 14px;
          -o-transform-origin: 14px 14px;
          transform-origin: 14px 14px; }
        .animate svg .colored-circle {
          -webkit-animation: coloredCircleGrow;
          -moz-animation: coloredCircleGrow;
          animation: coloredCircleGrow;
          -webkit-animation-duration: 0.2s;
          -moz-animation-duration: 0.2s;
          animation-duration: 0.2s;
          -webkit-transition-timing-function: ease-in-out;
          -moz-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
          -webkit-animation-name: coloredCircleGrow;
          -moz-animation-name: coloredCircleGrow;
          animation-name: coloredCircleGrow; }
        .animate svg .white-circle {
          fill: #f9fafb;
          -webkit-animation: whiteCircleGrow;
          -moz-animation: whiteCircleGrow;
          animation: whiteCircleGrow;
          -webkit-animation-duration: 0.3s;
          -moz-animation-duration: 0.3s;
          animation-duration: 0.3s;
          -webkit-transition-timing-function: ease-in-out;
          -moz-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
          -webkit-animation-name: whiteCircleGrow;
          -moz-animation-name: whiteCircleGrow;
          animation-name: whiteCircleGrow; }
        .animate svg .lightning-bolt {
          -webkit-animation: lightningGrow, lightningFlash;
          -moz-animation: lightningGrow, lightningFlash;
          animation: lightningGrow, lightningFlash;
          -webkit-animation-duration: 0.4s, 0.1s;
          -moz-animation-duration: 0.4s, 0.1s;
          animation-duration: 0.4s, 0.1s;
          -webkit-transition-timing-function: ease-in-out, ease-in-out;
          -moz-transition-timing-function: ease-in-out, ease-in-out;
          transition-timing-function: ease-in-out, ease-in-out;
          -webkit-animation-delay: 0s, 0.4s;
          -moz-animation-delay: 0s, 0.4s;
          animation-delay: 0s, 0.4s;
          -webkit-animation-iteration-count: 1, 3;
          -moz-animation-iteration-count: 1, 3;
          animation-iteration-count: 1, 3; }
        .animate svg .heart-rays {
          -webkit-animation: heartRaysGrow 0.5s linear;
          -moz-animation: heartRaysGrow 0.5s linear;
          animation: heartRaysGrow 0.5s linear; }
        .animate svg .heart-body {
          -webkit-animation: heartBodyGrow 0.5s linear;
          -moz-animation: heartBodyGrow 0.5s linear;
          animation: heartBodyGrow 0.5s linear;
          -webkit-transform-origin: 12px 12px;
          -moz-transform-origin: 12px 12px;
          -ms-transform-origin: 12px 12px;
          -o-transform-origin: 12px 12px;
          transform-origin: 12px 12px; }
        .animate svg .fire-flames {
          -webkit-animation: fireGrow 0.4s ease-in-out;
          -moz-animation: fireGrow 0.4s ease-in-out;
          animation: fireGrow 0.4s ease-in-out; }
        .animate svg .fire-ember {
          opacity: 0; }
        .animate svg .fire-ember.left {
          -webkit-transform: translate(5%, 120%) scale(0.8);
          -moz-transform: translate(5%, 120%) scale(0.8);
          -ms-transform: translate(5%, 120%) scale(0.8);
          -o-transform: translate(5%, 120%) scale(0.8);
          transform: translate(5%, 120%) scale(0.8);
          -webkit-animation: emberLeftRise 0.222s ease-out 0.479s;
          -moz-animation: emberLeftRise 0.222s ease-out 0.479s;
          animation: emberLeftRise 0.222s ease-out 0.479s; }
        .animate svg .fire-ember.middle {
          -webkit-transform: translate(80%, 20%) scale(0.8);
          -moz-transform: translate(80%, 20%) scale(0.8);
          -ms-transform: translate(80%, 20%) scale(0.8);
          -o-transform: translate(80%, 20%) scale(0.8);
          transform: translate(80%, 20%) scale(0.8);
          -moz-transform: translate(30%, 20%) scale(0.8);
          -webkit-animation: emberMiddleRise 0.24s ease-out 0.238s;
          -moz-animation: emberMiddleRise 0.24s ease-out 0.238s;
          animation: emberMiddleRise 0.24s ease-out 0.238s; }
        .animate svg .fire-ember.right {
          -webkit-transform: translate(220%, 20%) scale(0.6);
          -moz-transform: translate(220%, 20%) scale(0.6);
          -ms-transform: translate(220%, 20%) scale(0.6);
          -o-transform: translate(220%, 20%) scale(0.6);
          transform: translate(220%, 20%) scale(0.6);
          -moz-transform: translate(80%, 20%) scale(0.6);
          -webkit-animation: emberRightRise 0.239s ease-out 0.371s;
          -moz-animation: emberRightRise 0.239s ease-out 0.371s;
          animation: emberRightRise 0.239s ease-out 0.371s; }
        .animate svg .hundred-number {
          -webkit-animation: hundredGrow 0.4s ease-in-out;
          -moz-animation: hundredGrow 0.4s ease-in-out;
          animation: hundredGrow 0.4s ease-in-out; }
        .animate svg .mask {
          opacity: 0;
          fill: #f9fafb;
          -webkit-transform-origin: right center;
          -moz-transform-origin: right center;
          -ms-transform-origin: right center;
          -o-transform-origin: right center;
          transform-origin: right center; }
          .animate svg .mask.top {
            -webkit-animation: hundredUnderFirstLineGrow 0.6s ease-in-out;
            -moz-animation: hundredUnderFirstLineGrow 0.6s ease-in-out;
            animation: hundredUnderFirstLineGrow 0.6s ease-in-out; }
          .animate svg .mask.bottom {
            -webkit-animation: hundredUnderSecondLineGrow 0.7s ease-in-out;
            -moz-animation: hundredUnderSecondLineGrow 0.7s ease-in-out;
            animation: hundredUnderSecondLineGrow 0.7s ease-in-out; }
        .animate svg .top5-trophy {
          -webkit-transform-origin: 14px 14px;
          -moz-transform-origin: 14px 14px;
          -ms-transform-origin: 14px 14px;
          -o-transform-origin: 14px 14px;
          transform-origin: 14px 14px;
          -webkit-animation: top5Grow 0.4s ease-in-out;
          -moz-animation: top5Grow 0.4s ease-in-out;
          animation: top5Grow 0.4s ease-in-out; }
        .animate svg .top5-letter {
          opacity: 1;
          -webkit-transform: translate(0px, 0px);
          -moz-transform: translate(0px, 0px);
          -ms-transform: translate(0px, 0px);
          -o-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
          -webkit-animation: topSlideIn 0.5s linear;
          -moz-animation: topSlideIn 0.5s linear;
          animation: topSlideIn 0.5s linear; }
        .animate svg .top5-number {
          opacity: 1;
          -webkit-transform-origin: 14px 14px;
          -moz-transform-origin: 14px 14px;
          -ms-transform-origin: 14px 14px;
          -o-transform-origin: 14px 14px;
          transform-origin: 14px 14px;
          -webkit-animation: fiveShowAndGrow 0.5s linear;
          -moz-animation: fiveShowAndGrow 0.5s linear;
          animation: fiveShowAndGrow 0.5s linear; } }

.animate-removal {
  opacity: 0;
  -webkit-animation: fadeOutReaction 0.2s ease-in-out;
  -moz-animation: fadeOutReaction 0.2s ease-in-out;
  animation: fadeOutReaction 0.2s ease-in-out; }

li.animate + li {
  -webkit-animation: slideRightEffect 0.2s ease-in-out;
  -moz-animation: slideRightEffect 0.2s ease-in-out;
  animation: slideRightEffect 0.2s ease-in-out; }

.hide-wrap-and-nav .super-wrap,
.hide-wrap-and-nav .main-header {
  display: none; }
  @media only screen and (min-width: 37.5em) {
    .hide-wrap-and-nav .super-wrap,
    .hide-wrap-and-nav .main-header {
      display: block; } }
  html.no-rgba .hide-wrap-and-nav .super-wrap, html.no-rgba
  .hide-wrap-and-nav .main-header {
    display: block; }
  .desktop-only .hide-wrap-and-nav .super-wrap, .desktop-only
  .hide-wrap-and-nav .main-header {
    display: block; }

.hide-wrap-and-nav.no-rgba .super-wrap,
.hide-wrap-and-nav.no-rgba .main-header {
  display: block; }

@media only screen and (min-width: 37.5em) {
  .smooth-modal-display body {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden; } }

html.no-rgba .smooth-modal-display body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden; }

.desktop-only .smooth-modal-display body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden; }

.smooth-modal-display.no-rgba body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden; }

.smooth-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 200;
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }
  @media only screen and (min-width: 37.5em) {
    .smooth-modal-overlay {
      background-color: rgba(35, 42, 49, 0.8); } }
  html.no-rgba .smooth-modal-overlay {
    background-color: rgba(35, 42, 49, 0.8); }
  .desktop-only .smooth-modal-overlay {
    background-color: rgba(35, 42, 49, 0.8); }
  .no-rgba .smooth-modal-overlay {
    background-color: #8A8A8A; }
  .smooth-modal-display .smooth-modal-overlay {
    display: block; }
  .smooth-modal-slide .smooth-modal-overlay {
    opacity: 1; }

.smooth-modal {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 400;
  color: #232A31;
  background-color: #FFFFFF;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5); }
  @media only screen and (min-width: 37.5em) {
    .smooth-modal {
      position: absolute;
      width: 420px;
      left: 50%;
      margin-left: -210px;
      height: 80%;
      top: 10%;
      background-color: #FFFFFF;
      border-radius: 2px; } }
  html.no-rgba .smooth-modal {
    position: absolute;
    width: 420px;
    left: 50%;
    margin-left: -210px;
    height: 80%;
    top: 10%;
    background-color: #FFFFFF;
    border-radius: 2px; }
  .desktop-only .smooth-modal {
    position: absolute;
    width: 420px;
    left: 50%;
    margin-left: -210px;
    height: 80%;
    top: 10%;
    background-color: #FFFFFF;
    border-radius: 2px; }
  .smooth-modal-slide .smooth-modal {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .smooth-modal header {
    background-color: #38434F;
    width: 100%;
    z-index: 100;
    height: 3rem;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .smooth-modal header:after {
      content: "";
      display: table;
      clear: both; }
    @media only screen and (min-width: 6em) {
      .smooth-modal header {
        border-radius: 1px 1px 0px 0px; } }
    html.no-rgba .smooth-modal header {
      border-radius: 1px 1px 0px 0px; }
    .desktop-only .smooth-modal header {
      border-radius: 1px 1px 0px 0px; }
    .smooth-modal header .smooth-modal-back,
    .smooth-modal header .smooth-modal-close {
      margin-top: 0.25rem;
      padding: 1rem;
      cursor: pointer; }
      .smooth-modal header .smooth-modal-back > svg,
      .smooth-modal header .smooth-modal-close > svg {
        width: 1rem;
        height: 1rem;
        fill: #FFFFFF; }
        .smooth-modal header .smooth-modal-back > svg > *,
        .smooth-modal header .smooth-modal-close > svg > * {
          fill: #FFFFFF; }
    .smooth-modal header h2 {
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      width: 100%;
      z-index: 500;
      font-family: trade;
      font-weight: bold;
      text-transform: uppercase; }
  .smooth-modal .smooth-modal-content, .smooth-modal .smooth-modal-content-static {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 3rem;
    background-color: #FFFFFF; }
    .smooth-modal .smooth-modal-content.has-footer, .smooth-modal .has-footer.smooth-modal-content-static {
      bottom: 5rem; }
    .smooth-modal .smooth-modal-content.no-header, .smooth-modal .no-header.smooth-modal-content-static {
      top: 0; }
    .smooth-modal .smooth-modal-content h4, .smooth-modal .smooth-modal-content-static h4 {
      background-color: gainsboro;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      color: #232A31;
      text-align: center;
      padding: 0.25rem 0.5rem;
      font-family: trade;
      font-weight: bold;
      font-size: 0.75rem; }
    .smooth-modal .smooth-modal-content textarea, .smooth-modal .smooth-modal-content-static textarea {
      padding: 1rem;
      width: 100%;
      min-height: 100px;
      font-family: trade, sans-serif;
      border: 1px solid #FF6600;
      font-size: 1rem; }
  .smooth-modal .smooth-modal-content-static {
    position: static; }
  .smooth-modal section:after {
    content: "";
    display: table;
    clear: both; }
  .smooth-modal footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    border-top: 1px solid #cfcfcf;
    background-color: #E8E8E8;
    padding: 1rem;
    height: 5rem;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -o-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: end; }
    .smooth-modal footer:after {
      content: "";
      display: table;
      clear: both; }
    .smooth-modal footer button {
      margin-right: 0.5rem; }
      .smooth-modal footer button:last-child {
        margin-right: 0rem; }

.smooth-modal-season-switcher {
  background-color: #232A31;
  width: 100%;
  height: 3rem;
  color: #FFFFFF;
  text-align: center;
  z-index: 100;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .smooth-modal-season-switcher .season-next,
  .smooth-modal-season-switcher .season-prev {
    padding: 1rem;
    cursor: pointer; }
    .smooth-modal-season-switcher .season-next svg,
    .smooth-modal-season-switcher .season-prev svg {
      width: 1rem;
      height: 1rem;
      fill: #FFFFFF; }
      .smooth-modal-season-switcher .season-next svg > *,
      .smooth-modal-season-switcher .season-prev svg > * {
        fill: #FFFFFF; }
    .smooth-modal-season-switcher .season-next.disabled svg > *,
    .smooth-modal-season-switcher .season-prev.disabled svg > * {
      fill: gray; }

.smooth-modal-selection-list li {
  color: #232A31;
  padding: 1.25rem 1rem;
  width: 100%;
  border-bottom: 1px solid #E8E8E8;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .smooth-modal-selection-list li:after {
    content: "";
    display: table;
    clear: both; }
  .smooth-modal-selection-list li .sm-selection-list-item {
    width: 100%; }
  .smooth-modal-selection-list li .sm-selection-list-item-icon {
    width: 0.75rem;
    height: 0.75rem; }
    .smooth-modal-selection-list li .sm-selection-list-item-icon svg {
      fill: #232A31; }

.smooth-modal-checkbox-list li {
  color: #232A31;
  padding: 0 1rem 1rem 1rem;
  width: 100%;
  cursor: pointer;
  line-height: 1.5rem;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .smooth-modal-checkbox-list li:after {
    content: "";
    display: table;
    clear: both; }
  .smooth-modal-checkbox-list li:first-child {
    padding-top: 1rem; }

.smooth-modal {
  background: none;
  border-radius: 0;
  height: 100%;
  min-height: 100%;
  max-height: 100%; }
  @media only screen and (min-width: 37.5em) {
    .smooth-modal {
      border-radius: 3px;
      width: 522px;
      margin-left: -261px;
      min-height: 288px;
      max-height: 590px; } }
  html.no-rgba .smooth-modal {
    border-radius: 3px;
    width: 522px;
    margin-left: -261px;
    min-height: 288px;
    max-height: 590px; }
  .desktop-only .smooth-modal {
    border-radius: 3px;
    width: 522px;
    margin-left: -261px;
    min-height: 288px;
    max-height: 590px; }
  .smooth-modal header .smooth-modal-close {
    fill: white;
    margin: 0;
    padding: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
    width: .75rem;
    height: .75rem;
    z-index: 500; }
    .smooth-modal header .smooth-modal-close > svg {
      position: relative;
      top: -.5rem;
      left: -.5rem; }

.reaction-details-modal .smooth-modal-content, .reaction-details-modal .smooth-modal .smooth-modal-content-static, .smooth-modal .reaction-details-modal .smooth-modal-content-static {
  overflow: visible; }

.reaction-details-modal .reaction-nav {
  background-color: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
  display: block;
  display: flex;
  flex-wrap: nowrap; }
  .reaction-details-modal .reaction-nav > li {
    flex-grow: 1;
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .reaction-details-modal .reaction-nav > li:hover {
      background-color: #F5F5F5; }
    .no-flexbox .reaction-details-modal .reaction-nav > li {
      min-width: 20%; }
    .reaction-details-modal .reaction-nav > li.selected .triangle-indicator {
      display: block; }
  .reaction-details-modal .reaction-nav .reaction-nav-item {
    display: block;
    min-height: 1.75rem;
    padding: 0.5rem; }
    .reaction-details-modal .reaction-nav .reaction-nav-item > img {
      display: block;
      margin: 0 auto;
      width: 1.75rem;
      height: 1.75rem; }
  .reaction-details-modal .reaction-nav .triangle-indicator {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -11px; }
    .reaction-details-modal .reaction-nav .triangle-indicator .top {
      position: absolute;
      top: 0;
      left: 1px;
      width: 0;
      height: 0;
      z-index: 300;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #FFFFFF; }
    .reaction-details-modal .reaction-nav .triangle-indicator .bottom {
      position: absolute;
      width: 0;
      height: 0;
      z-index: 200;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 12px solid #E8E8E8; }

.reaction-details-modal .reaction-users-composite {
  height: 100%;
  overflow: visible; }

.reaction-details-modal .reaction-users-wrapper {
  height: -webkit-calc(100% - 45px);
  height: calc(100% - 45px);
  overflow-y: auto;
  overflow-x: hidden; }

.reaction-details-modal .reaction-users {
  height: auto;
  overflow: hidden; }
  .reaction-details-modal .reaction-users > li:first-child:before {
    content: "";
    display: block;
    padding: .25rem;
    width: 100%; }
  .reaction-details-modal .reaction-users > li:last-child .reaction-user {
    border-bottom: none; }

.reaction-details-modal .reaction-user {
  border-bottom: 1px solid #E8E8E8;
  margin: 0 1rem;
  min-height: 4.5rem;
  padding: 0.75rem 0;
  position: relative; }
  .reaction-details-modal .reaction-user .user-avatar {
    display: block;
    position: absolute;
    left: 0;
    top: 0.75rem; }
    .reaction-details-modal .reaction-user .user-avatar > img {
      width: 2.8125rem;
      height: 2.8125rem;
      border-radius: 50%; }
  .reaction-details-modal .reaction-user .user-fields {
    display: inline-block;
    line-height: 1.15;
    margin-right: 3.5rem;
    margin-left: 3.75rem; }
    .reaction-details-modal .reaction-user .user-fields h2 {
      margin-bottom: .125rem; }
      .reaction-details-modal .reaction-user .user-fields h2 a:hover {
        text-decoration: underline; }
    .reaction-details-modal .reaction-user .user-fields h3 {
      color: #8A8A8A;
      font-size: .75rem;
      margin-bottom: .125rem; }
      .reaction-details-modal .reaction-user .user-fields h3:last-child {
        margin-bottom: 0; }
    .reaction-details-modal .reaction-user .user-fields .username {
      color: #38434F;
      font-weight: bold; }
      .reaction-details-modal .reaction-user .user-fields .username:visited {
        color: #38434F; }
    .reaction-details-modal .reaction-user .user-fields .user-position {
      color: #8A8A8A;
      font-size: .75rem; }
    .reaction-details-modal .reaction-user .user-fields .user-sport {
      display: inline-block;
      position: relative;
      top: 0.125rem;
      width: .75rem;
      height: .75rem; }
      .reaction-details-modal .reaction-user .user-fields .user-sport svg, .reaction-details-modal .reaction-user .user-fields .user-sport path, .reaction-details-modal .reaction-user .user-fields .user-sport polygon {
        color: #8A8A8A;
        width: .75rem;
        height: .75rem; }
  .reaction-details-modal .reaction-user .follow-user {
    position: absolute;
    right: 0;
    top: .75rem; }

.reaction-details-modal .infinite-css-spinner-wrapper {
  overflow: hidden; }

.reaction-details-modal .infinite-css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 3rem;
  width: 3rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .reaction-details-modal .infinite-css-spinner {
    display: none; }

.reaction-details-modal .btn.friendship .following {
  display: none; }

.reaction-details-modal .btn.friendship.following .follow {
  display: none; }

.reaction-details-modal .btn.friendship.following .following {
  display: flex; }

.reaction-details-modal .btn.friendship.following:hover .following {
  border-color: #5c8d1c;
  background-color: #5c8d1c; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.uni-btn.follow-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .uni-btn.follow-button svg {
    margin-right: 0.25rem;
    height: 16px;
    width: 16px;
    position: relative;
    top: -1px; }
  .uni-btn.follow-button.following {
    background: #78a100; }
    .uni-btn.follow-button.following path, .uni-btn.follow-button.following rect {
      color: #e6f2ff;
      fill: #e6f2ff; }
    .uni-btn.follow-button.following.allow-unfollow:hover {
      background: #8bbb00; }
  .uni-btn.follow-button.no-label {
    height: 40px;
    width: 40px;
    padding: 0; }
    .uni-btn.follow-button.no-label .label {
      display: none; }
  .uni-btn.follow-button.uni-btn--primary path, .uni-btn.follow-button.uni-btn--primary rect {
    color: #e6f2ff;
    fill: #e6f2ff; }

.reaction-container {
  padding: .5rem 0;
  border-top: 1px solid #e8e8e8; }

.reaction-details {
  margin-bottom: .875rem;
  width: 100%; }

.reaction-details-preview,
.reaction-details-preview-alternate {
  color: rgba(19, 41, 63, 0.65);
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: .875rem;
  line-height: 1; }
  .reaction-details-preview:hover,
  .reaction-details-preview-alternate:hover {
    text-decoration: underline; }
  .reaction-details-preview:visited,
  .reaction-details-preview-alternate:visited {
    color: rgba(19, 41, 63, 0.65); }
  .reaction-details-preview .emphasis,
  .reaction-details-preview-alternate .emphasis {
    font-weight: bold; }
  .reaction-details-preview span:after,
  .reaction-details-preview-alternate span:after {
    content: '';
    /* Adds spacing between the preview message parts */ }
  .reaction-details-preview span:last-child:after,
  .reaction-details-preview-alternate span:last-child:after {
    content: none;
    /* Remove spacing behind the last preview message part */ }

.reaction-actions {
  display: flex;
  flex-direction: row; }
  .no-flexbox .reaction-actions:after {
    content: "";
    display: table;
    clear: both; }

.add-reaction {
  position: relative; }
  .no-flexbox .add-reaction {
    display: inline-block;
    float: left; }

.add-reaction-popup {
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #E8E8E8;
  position: absolute;
  left: 0;
  top: -3rem;
  width: max-content;
  height: auto;
  z-index: 100;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); }
  .add-reaction-popup .triangle-indicator {
    position: absolute;
    left: 1rem;
    bottom: 0; }
    .add-reaction-popup .triangle-indicator .top {
      position: absolute;
      top: 0px;
      left: 1px;
      width: 0;
      height: 0;
      z-index: 300;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #FFFFFF; }
    .add-reaction-popup .triangle-indicator .bottom {
      position: absolute;
      width: 0;
      height: 0;
      z-index: 200;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 12px solid #E8E8E8; }
  .add-reaction-popup .available-reaction-list {
    display: inline-block;
    white-space: nowrap; }
    .add-reaction-popup .available-reaction-list li {
      display: inline-block; }
  .add-reaction-popup .available-reaction {
    cursor: pointer;
    padding: .875rem; }
    .add-reaction-popup .available-reaction.reacted {
      cursor: default; }
      .add-reaction-popup .available-reaction.reacted:hover {
        background-color: transparent; }
      .add-reaction-popup .available-reaction.reacted .reaction-icon {
        opacity: 0.3; }
    .add-reaction-popup .available-reaction:hover {
      background-color: #F5F5F5; }
    .add-reaction-popup .available-reaction .reaction-icon {
      width: 1.5rem;
      height: 1.5rem; }

@media only screen and (min-width: 35em) {
  .add-reaction-btn {
    padding: 0.5rem 1rem; } }

.desktop-only .add-reaction-btn {
  padding: 0.5rem 1rem; }

.add-reaction-btn svg, .add-reaction-btn path, .add-reaction-btn polygon {
  width: 0.75rem;
  height: 0.75rem;
  fill: #232a31;
  margin-right: 0; }
  @media only screen and (min-width: 35em) {
    .add-reaction-btn svg, .add-reaction-btn path, .add-reaction-btn polygon {
      margin-right: 0.25rem; } }
  .desktop-only .add-reaction-btn svg, .desktop-only .add-reaction-btn path, .desktop-only .add-reaction-btn polygon {
    margin-right: 0.25rem; }

@media only screen and (min-width: 25em) {
  .add-reaction-btn {
    font-size: 14px; } }

.desktop-only .add-reaction-btn {
  font-size: 14px; }

.add-reaction-icon {
  display: inline; }

.reaction-btn-text {
  display: none; }
  @media only screen and (min-width: 35em) {
    .reaction-btn-text {
      display: inline; } }
  .desktop-only .reaction-btn-text {
    display: inline; }

.reaction-list {
  display: inline-block; }
  .no-flexbox .reaction-list {
    float: left;
    width: calc(100% - 150px); }

.reaction-item {
  display: inline-block;
  margin: 1px;
  float: left; }

.reaction-container .reaction {
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: .25rem;
  min-width: 3.5rem;
  padding: .375rem;
  height: 2.375rem; }
  .reaction-container .reaction:hover {
    border: 1px solid #00aeef;
    border-radius: 2px; }
  .reaction-container .reaction.reacted {
    border-color: rgba(0, 174, 239, 0.3);
    border-radius: 2px; }
    .reaction-container .reaction.reacted:hover {
      border-color: #00aeef; }
    .reaction-container .reaction.reacted.animate {
      border-color: transparent; }
      .reaction-container .reaction.reacted.animate:hover {
        border-color: transparent; }
    .reaction-container .reaction.reacted .reaction-count > span {
      color: rgba(0, 174, 239, 0.7);
      font-weight: bold; }

.reaction-icon {
  margin-right: .125rem;
  width: 1.5rem;
  height: 1.5rem; }

.reaction-count {
  display: inline-block;
  margin-top: .2rem;
  text-align: center;
  vertical-align: top; }
  .reaction-count > span {
    color: #8A8A8A;
    font-size: .75rem; }

.tagging-container {
  padding: .5rem 0;
  border-top: 1px solid #e8e8e8; }
  .tagging-container.recommendation-design {
    padding: 0;
    padding: 0 0 0 2px;
    border-top: none; }
    .tagging-container.recommendation-design .tagging-message {
      font-family: helvetica, arial, sans-serif; }
    .tagging-container.recommendation-design .first-tagged {
      color: rgba(19, 41, 63, 0.8); }
    .tagging-container.recommendation-design .second-tagged {
      color: rgba(19, 41, 63, 0.8); }
    .tagging-container.recommendation-design .tagging-details-preview {
      color: rgba(19, 41, 63, 0.8); }

.tagging-message {
  width: 165px;
  height: 15px;
  font-family: trade;
  font-size: 12px;
  color: rgba(19, 41, 63, 0.4);
  display: inline; }

.first-tagged {
  color: #4E5D6C; }
  .first-tagged:hover {
    text-decoration: underline; }

.second-tagged {
  color: #4E5D6C; }
  .second-tagged:hover {
    text-decoration: underline; }

.tagging-details-preview {
  color: #4E5D6C; }
  .tagging-details-preview:hover {
    text-decoration: underline; }

.tag-count {
  display: none; }

.tag-list {
  display: none; }

.author-name-video {
  width: 117px;
  height: 21px;
  font-family: trade;
  font-size: 16px;
  font-weight: normal;
  color: #4E5D6C;
  display: inline; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  background: #f9fafb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: trade, sans-serif;
  margin: 0;
  overflow-x: hidden;
  position: relative; }

.smooth-modal-display body {
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: inherit;
  overflow-y: scroll; }

html {
  margin: 0; }

a {
  text-decoration: none; }

.ko-wrap {
  background: transparent; }

@media only screen and (min-width: 57.25em) {
  .ko-subheader {
    margin-bottom: 20px; } }

.desktop-only .ko-subheader {
  margin-bottom: 20px; }

.container-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: trade, sans-serif;
  margin: 0 auto;
  max-width: 1340px;
  width: 100%; }
  @media only screen and (min-width: 57.25em) {
    .container-wrap {
      margin-top: 1.5rem;
      padding: 0 20px; } }
  .desktop-only .container-wrap {
    margin-top: 1.5rem;
    padding: 0 20px; }

@media only screen and (min-width: 57.25em) {
  .container-top {
    margin-top: 20px; } }

.desktop-only .container-top {
  margin-top: 20px; }

.panel-left {
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  flex-basis: 100%;
  -ms-flex-preferred-size: 100%; }
  @media only screen and (min-width: 66em) {
    .panel-left {
      -webkit-flex-basis: auto;
      -moz-flex-basis: auto;
      flex-basis: auto;
      -ms-flex-preferred-size: auto;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      padding-right: 20px;
      margin-bottom: 20px; } }
  .desktop-only .panel-left {
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    flex-basis: auto;
    -ms-flex-preferred-size: auto;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 20px;
    margin-bottom: 20px; }

.panel-right {
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  width: 100%; }
  @media only screen and (min-width: 57.25em) {
    .panel-right {
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
      flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
      width: 100%; } }
  .desktop-only .panel-right {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    width: 100%; }
  @media only screen and (min-width: 66em) {
    .panel-right {
      -webkit-flex-basis: 340px;
      -moz-flex-basis: 340px;
      flex-basis: 340px;
      -ms-flex-preferred-size: 340px;
      width: 340px; } }
  .desktop-only .panel-right {
    -webkit-flex-basis: 340px;
    -moz-flex-basis: 340px;
    flex-basis: 340px;
    -ms-flex-preferred-size: 340px;
    width: 340px; }

.html-player-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative; }
  .html-player-container .player-controls-bar-center, .html-player-container .hudl-guys {
    display: none !important; }

.html-player {
  position: relative;
  overflow: hidden; }
  .html-player video {
    width: 100%; }
  .html-player.fullscreen {
    margin: 0 0;
    max-width: 100%;
    width: 100%; }

.video-information-container {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-flex-direction: column-reverse; }
  @media only screen and (min-width: 57.25em) {
    .video-information-container {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row; } }
  .desktop-only .video-information-container {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row; }
  @media only screen and (min-width: 66em) {
    .video-information-container {
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      box-orient: vertical;
      -webkit-box-direction: reverse;
      -moz-box-direction: reverse;
      box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -moz-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -ms-flex-direction: column-reverse; } }
  .desktop-only .video-information-container {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-direction: column-reverse; }
  @media only screen and (min-width: 80em) {
    .video-information-container {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row; } }
  .desktop-only .video-information-container {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row; }

.highlight-info-reactions-container {
  margin-left: 0;
  max-width: 100%;
  padding: 0 16px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  flex-shrink: 1;
  -ms-flex-negative: 1; }
  @media only screen and (min-width: 57.25em) {
    .highlight-info-reactions-container {
      padding: 0;
      margin-left: 1.25rem;
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
      flex-basis: 100%;
      -ms-flex-preferred-size: 100%; } }
  .desktop-only .highlight-info-reactions-container {
    padding: 0;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%; }
  @media only screen and (min-width: 66em) {
    .highlight-info-reactions-container {
      margin-left: 0;
      -webkit-flex-basis: auto;
      -moz-flex-basis: auto;
      flex-basis: auto;
      -ms-flex-preferred-size: auto; } }
  .desktop-only .highlight-info-reactions-container {
    margin-left: 0;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    flex-basis: auto;
    -ms-flex-preferred-size: auto; }
  @media only screen and (min-width: 80em) {
    .highlight-info-reactions-container {
      margin-left: 1.25rem;
      -webkit-flex-basis: 100%;
      -moz-flex-basis: 100%;
      flex-basis: 100%;
      -ms-flex-preferred-size: 100%; } }
  .desktop-only .highlight-info-reactions-container {
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%; }

.highlight-info {
  padding-bottom: 0.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column; }
  @media only screen and (min-width: 57.25em) {
    .highlight-info {
      padding-bottom: 1.25rem;
      border-bottom: 1px solid #e3e5e8; } }
  .desktop-only .highlight-info {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #e3e5e8; }
  @media only screen and (min-width: 80em) {
    .highlight-info {
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      box-orient: vertical;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      flex-direction: column;
      -ms-flex-direction: column; } }
  .desktop-only .highlight-info {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column; }
  .highlight-info h1 {
    font-size: 30px;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    flex-shrink: 1;
    -ms-flex-negative: 1;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    flex-basis: auto;
    -ms-flex-preferred-size: auto; }
    @media only screen and (min-width: 43.75em) {
      .highlight-info h1 {
        font-size: 40px; } }
    .desktop-only .highlight-info h1 {
      font-size: 40px; }

.video-title {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -moz-box-align: end;
  box-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
  -ms-flex-align: end;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  box-lines: single;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 16px 0 8px;
  color: #13293f; }
  @media only screen and (min-width: 57.25em) {
    .video-title {
      padding: 16px 0 0; } }
  .desktop-only .video-title {
    padding: 16px 0 0; }

.metadata {
  height: 18px;
  color: #4E5D6C;
  padding-top: 0.5rem; }
  @media only screen and (min-width: 57.25em) {
    .metadata {
      align-self: flex-end;
      padding-top: 0; } }
  .desktop-only .metadata {
    align-self: flex-end;
    padding-top: 0; }
  .metadata > div {
    margin-right: 1px; }
  .metadata .metadata-views {
    display: inline-block; }

.metadata-duration {
  display: inline-block; }

.metadata-updated {
  display: inline-block; }

.bio-link {
  color: inherit;
  text-decoration: none; }

.profile-link {
  color: rgba(19, 41, 63, 0.65);
  text-decoration: none;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  font-family: helvetica, arial, sans-serif;
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.42857;
  border-radius: 3px;
  height: 30px; }
  .profile-link.margin-needed {
    margin-bottom: 10px; }

.owner-container {
  border-top: 1px solid #E8E8E8;
  margin-top: 0;
  min-width: 0;
  padding-top: 20px;
  position: relative;
  display: none; }
  @media only screen and (min-width: 66em) {
    .owner-container {
      display: inline;
      text-align: left;
      min-width: 0;
      width: 100%;
      margin-top: 0;
      padding-top: 20px;
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid #E8E8E8;
      border-right: none; } }
  .desktop-only .owner-container {
    display: inline;
    text-align: left;
    min-width: 0;
    width: 100%;
    margin-top: 0;
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid #E8E8E8;
    border-right: none; }
  @media only screen and (min-width: 80em) {
    .owner-container {
      text-align: center;
      min-width: 200px;
      max-width: 200px;
      margin-top: 20px;
      padding-left: 20px;
      padding-top: 0;
      padding-right: 20px;
      padding-bottom: 0;
      border-radius: 0;
      border-left: none;
      border-top: none;
      border-bottom: none; } }
  .desktop-only .owner-container {
    text-align: center;
    min-width: 200px;
    max-width: 200px;
    margin-top: 20px;
    padding-left: 20px;
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
    border-radius: 0;
    border-left: none;
    border-top: none;
    border-bottom: none; }

.owner-info .player-photo {
  background-color: #232A31;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  height: 100%;
  width: 100%; }
  .owner-info .player-photo img {
    width: 100%;
    height: 100%;
    border: none; }

.owner-info .thumb-container-badge {
  background-color: #e9e9e9;
  background-position: center center;
  background-size: 2rem 2rem;
  border: 1px solid #222;
  border-radius: 50%;
  height: 2rem;
  left: -1rem;
  position: absolute;
  top: 0.25rem;
  width: 2rem; }

.owner-photo-holder {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  border: none;
  margin-right: 20px;
  position: relative; }
  .owner-photo-holder svg {
    height: 100%;
    width: 100%; }
  @media only screen and (min-width: 57.25em) {
    .owner-photo-holder {
      margin: 0 auto 10px; } }
  .desktop-only .owner-photo-holder {
    margin: 0 auto 10px; }
  @media only screen and (min-width: 66em) {
    .owner-photo-holder {
      margin-right: 20px; } }
  .desktop-only .owner-photo-holder {
    margin-right: 20px; }
  @media only screen and (min-width: 80em) {
    .owner-photo-holder {
      margin: 0 auto 10px; } }
  .desktop-only .owner-photo-holder {
    margin: 0 auto 10px; }

.player-info {
  margin: 0;
  padding-top: 10px;
  font-size: .875em;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column; }
  @media only screen and (min-width: 57.25em) {
    .player-info {
      margin: 10px 0 10px; } }
  .desktop-only .player-info {
    margin: 10px 0 10px; }
  @media only screen and (min-width: 66em) {
    .player-info {
      margin: 0; } }
  .desktop-only .player-info {
    margin: 0; }
  .player-info.no-margin {
    padding-top: 0; }
  .player-info.marginfix {
    margin-bottom: 0;
    padding-top: 1rem; }
    .player-info.marginfix:hover {
      color: rgba(0, 0, 0, 0.4); }
  .player-info.cta-buttons {
    padding-top: 1rem; }
  .player-info.part-inline {
    display: inline;
    color: #4e5c69;
    padding-top: 0.25rem; }
    @media only screen and (min-width: 57.25em) {
      .player-info.part-inline {
        display: block;
        margin-top: 0; } }
    .desktop-only .player-info.part-inline {
      display: block;
      margin-top: 0; }
    @media only screen and (min-width: 66em) {
      .player-info.part-inline {
        display: inline; } }
    .desktop-only .player-info.part-inline {
      display: inline; }
    @media only screen and (min-width: 80em) {
      .player-info.part-inline {
        display: block; } }
    .desktop-only .player-info.part-inline {
      display: block; }
  .player-info.place-or-team {
    color: rgba(19, 41, 63, 0.4); }

.owner-info,
.owner-specifics {
  display: inline-block;
  vertical-align: top; }
  @media only screen and (min-width: 57.25em) {
    .owner-info,
    .owner-specifics {
      display: block; } }
  .desktop-only .owner-info, .desktop-only
  .owner-specifics {
    display: block; }
  @media only screen and (min-width: 66em) {
    .owner-info,
    .owner-specifics {
      display: inline-block; } }
  .desktop-only .owner-info, .desktop-only
  .owner-specifics {
    display: inline-block; }
  @media only screen and (min-width: 80em) {
    .owner-info,
    .owner-specifics {
      display: block; } }
  .desktop-only .owner-info, .desktop-only
  .owner-specifics {
    display: block; }

.owner-follow {
  position: absolute;
  right: 0;
  top: 20px; }
  @media only screen and (min-width: 57.25em) {
    .owner-follow {
      position: relative;
      top: 0; } }
  .desktop-only .owner-follow {
    position: relative;
    top: 0; }
  @media only screen and (min-width: 66em) {
    .owner-follow {
      position: absolute;
      top: 20px; } }
  .desktop-only .owner-follow {
    position: absolute;
    top: 20px; }
  @media only screen and (min-width: 80em) {
    .owner-follow {
      position: relative;
      top: 0; } }
  .desktop-only .owner-follow {
    position: relative;
    top: 0; }
  .owner-follow .follow-button {
    height: 40px;
    width: 40px;
    padding: 0; }
    .owner-follow .follow-button .label {
      display: none; }
    @media only screen and (min-width: 35em) {
      .owner-follow .follow-button {
        padding: 0 1.25em;
        height: 30px;
        width: auto; }
        .owner-follow .follow-button .label {
          display: inline; } }
    .desktop-only .owner-follow .follow-button {
      padding: 0 1.25em;
      height: 30px;
      width: auto; }
      .desktop-only .owner-follow .follow-button .label {
        display: inline; }

.follow-button .following {
  display: none; }

.follow-button.following .following {
  display: block; }

.follow-button.following .follow {
  display: none; }

.button-social {
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  color: #3e3e3e;
  border: 1px solid #a6adb5;
  padding: 9px;
  width: 34px;
  height: 34px;
  text-align: center;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  transition: all .25s ease;
  border-radius: 50%; }
  @media only screen and (min-width: 43.75em) {
    .button-social {
      opacity: 1;
      font-size: 16px;
      position: relative; }
      .button-social > span {
        position: relative;
        top: 6px; } }
  .desktop-only .button-social {
    opacity: 1;
    font-size: 16px;
    position: relative; }
    .desktop-only .button-social > span {
      position: relative;
      top: 6px; }
  @media only screen and (min-width: 57.25em) {
    .button-social {
      margin-right: 8px; } }
  .desktop-only .button-social {
    margin-right: 8px; }
  .button-social:hover {
    color: #3e3e3e;
    border: 1px solid transparent; }

.athlete-info-cta {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media only screen and (min-width: 57.25em) {
    .athlete-info-cta {
      margin-top: 0;
      margin-left: auto; } }
  .desktop-only .athlete-info-cta {
    margin-top: 0;
    margin-left: auto; }

.athlete-info-cta-twitter:hover {
  background: #68b2d9; }
  .athlete-info-cta-twitter:hover > svg {
    fill: white; }

.athlete-info-cta-facebook:hover {
  background: #1653ae; }
  .athlete-info-cta-facebook:hover > svg {
    fill: white; }

.athlete-info-cta-email:hover {
  background: #999999; }
  .athlete-info-cta-email:hover > svg {
    fill: white; }

.athlete-info-cta-email svg {
  top: -2px !important;
  right: 0 !important; }

.button-standard {
  background-color: none;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  color: #13293f;
  cursor: pointer;
  display: inline-block;
  font: normal 14px trade;
  font-size: .875rem;
  line-height: 20px;
  margin-bottom: 0;
  margin-right: .5rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  text-rendering: optimizeLegibility;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: all, 0.25s, ease;
  -moz-transition: all, 0.25s, ease;
  transition: all, 0.25s, ease; }
  .button-standard:hover {
    border: 1px solid #3e3e3e; }

.button-social svg {
  position: relative;
  top: -2px;
  fill: #a6adb5;
  height: 1.25rem;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  transition: all .25s ease;
  right: 6%; }

.button-social:last-child {
  margin-right: 0; }

.video-owner {
  font-family: helvetica, arial, sans-serif;
  color: #4e5c69;
  margin-bottom: 0.5rem; }
  @media only screen and (min-width: 64em) {
    .video-owner {
      display: none;
      margin-bottom: 0; } }
  .desktop-only .video-owner {
    display: none;
    margin-bottom: 0; }

.cta-container {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
  padding-top: 8px;
  padding-bottom: 8px;
  float: right; }
  @media only screen and (min-width: 57.25em) {
    .cta-container {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row;
      padding-top: 0;
      padding-bottom: 0; } }
  .desktop-only .cta-container {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    padding-top: 0;
    padding-bottom: 0; }
  @media only screen and (min-width: 66em) {
    .cta-container {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row; } }
  .desktop-only .cta-container {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row; }
  @media only screen and (min-width: 80em) {
    .cta-container {
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row; } }
  .desktop-only .cta-container {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row; }

.ctas {
  clear: both; }
  @media only screen and (min-width: 31.25em) {
    .ctas {
      clear: none; } }
  .desktop-only .ctas {
    clear: none; }

.player-name {
  font-size: 1rem;
  margin-top: 0.5rem;
  color: #13293f;
  line-height: 1.125;
  word-wrap: break-word;
  font-weight: 400;
  font-family: helvetica, arial, sans-serif;
  font-weight: bold; }

a.player-name:hover {
  text-decoration: underline; }

#div-gpt-ad-video-page-desktop-ad-id,
#div-gpt-ad-video-page-mobile-ad-id {
  text-align: center; }

.video-ad {
  display: none;
  font-size: 0.72em;
  width: 100%;
  height: 65px;
  margin: 0;
  clear: both; }
  @media only screen and (min-width: 321px) {
    .video-ad {
      display: block; } }
  @media only screen and (min-width: 25em) {
    .video-ad {
      margin-top: 20px;
      padding: 0;
      height: 65px; } }
  .desktop-only .video-ad {
    margin-top: 20px;
    padding: 0;
    height: 65px; }
  @media only screen and (min-width: 43.75em) {
    .video-ad {
      margin-top: 20px;
      height: 115px; } }
  .desktop-only .video-ad {
    margin-top: 20px;
    height: 115px; }
  @media only screen and (min-width: 66em) {
    .video-ad {
      margin-top: 20px;
      height: 270px;
      margin: 0;
      padding: 0; } }
  .desktop-only .video-ad {
    margin-top: 20px;
    height: 270px;
    margin: 0;
    padding: 0; }
  .video-ad iframe {
    margin: 0 auto;
    display: table; }
  @media only screen and (min-width: 66em) {
    .video-ad.highlights-page {
      width: 740px;
      margin: 20px auto;
      height: 134px; } }
  .desktop-only .video-ad.highlights-page {
    width: 740px;
    margin: 20px auto;
    height: 134px; }
  .video-ad.below-suggestions {
    margin-top: 20px;
    margin-bottom: 0px; }

.order-button {
  display: none;
  padding: 0.5rem;
  vertical-align: top;
  font-family: helvetica, arial, sans-serif;
  font-size: 16px;
  color: #86919a;
  font-weight: 700;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  @media only screen and (min-width: 37.5em) {
    .order-button {
      display: table; } }
  .desktop-only .order-button {
    display: table; }
  .order-button:hover {
    color: #555e65; }

.highlight-info-description {
  white-space: pre-line; }
  .highlight-info-description .highlight-info-description__more {
    display: none;
    font-weight: bold;
    cursor: pointer; }
  .highlight-info-description.highlight-info-description--truncated .highlight-info-description__more {
    display: inline; }

.render-status {
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
  background: #232a31; }
  .render-status p {
    margin: auto;
    text-align: center;
    padding: 10% 30px 0;
    color: white;
    font-family: trade;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5; }
    @media only screen and (min-width: 7em) {
      .render-status p {
        padding-top: 10%;
        font-size: 24px; } }
    .desktop-only .render-status p {
      padding-top: 10%;
      font-size: 24px; }
  .render-status .color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.18;
    pointer-events: none;
    -webkit-transition: opacity 0.25s ease-out;
    -moz-transition: opacity 0.25s ease-out;
    transition: opacity 0.25s ease-out;
    z-index: 10; }
    .render-status .color-overlay.hide {
      opacity: 0; }
  .render-status .help {
    margin-top: 20px;
    position: relative;
    z-index: 50;
    text-align: center; }
    .render-status .help a {
      padding: 10px 30px 10px 30px;
      background-color: rgba(255, 99, 0, 0.8);
      color: white;
      border-radius: 30px;
      max-width: 90%;
      display: inline-block;
      -webkit-transition: background-color 0.25s ease-in-out;
      -moz-transition: background-color 0.25s ease-in-out;
      transition: background-color 0.25s ease-in-out;
      text-decoration: none;
      position: relative;
      font-size: 11px; }
      .render-status .help a:hover {
        background-color: #ff6300; }
      .render-status .help a i {
        margin-left: 10px; }
        @media only screen and (min-width: 6em) {
          .render-status .help a i {
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -6px; } }
        .desktop-only .render-status .help a i {
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -6px; }
      @media only screen and (min-width: 6em) {
        .render-status .help a {
          padding-right: 40px;
          font-size: 16px; } }
      .desktop-only .render-status .help a {
        padding-right: 40px;
        font-size: 16px; }
    @media only screen and (min-width: 6em) {
      .render-status .help {
        margin-top: 40px; } }
    .desktop-only .render-status .help {
      margin-top: 40px; }

#tester {
  display: none; }

#adblock-tester {
  display: none; }

.ad-block-alert {
  font-family: trade;
  font-weight: normal;
  line-height: 1.5;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  padding-bottom: 56.25%;
  margin-bottom: -56.25%;
  margin-top: 0;
  color: white;
  background: #232a31;
  height: 0; }
